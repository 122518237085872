import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import './App.css';
import Header from './components/Header/Header';
import EmailContextProvider from './context/emailContext';
import ErrorBoundary from './ErrorBoundary';
import Routes from './routes';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
        // Navigate to the default route upon component mount
        navigate('/lookup'); // Replace '/default-route' with your desired default route
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <ErrorBoundary>
      <EmailContextProvider>
          <div className="container">
            <Sidebar />
            <div className='content'>
              <Header />
              <Routes />
            </div>
          </div>
      </EmailContextProvider>
    </ErrorBoundary>
  );
};

export default App;
