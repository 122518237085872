import { RiDeleteBin6Line, RiFileCopyLine } from 'react-icons/ri';
import './styles.css';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getEmailStatus } from '../../../services/emailStatus';
import { addBusinessDays } from '../../../utils/addBusinessDays';
import { getEmailDate } from '../../../services/emailDate';
import { addToStoplist } from '../../../services/addToStoplist';
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import { doRenderJobsTooltip } from "../tooltips/renderJobsTooltip";
import { emailInSpamDb as checkEmailInSpam } from "../../../services/emailInSpamDb";

const SuggestedDateCell = ({ date, isServerFetched }) => {
    const isDateOlderThanTwoWeeks = (date) => {
        // Get the current date in the NYC timezone
        const nowNYC = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });

        // Calculate the date two weeks ago
        const twoWeeksAgo = new Date(nowNYC);
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

        // Convert the provided date to a Date object
        const providedDate = new Date(date);

        // Check if the provided date is older than two weeks ago
        return providedDate <= twoWeeksAgo;
    };

    const isDateInFuture = (date) => {
        const nowNYC = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
        return new Date(date) > new Date(nowNYC);
    };

    const cellStyle = {
        backgroundColor: isServerFetched ? '#c8f7c5' : 'transparent',
        ...(isServerFetched && date && isDateOlderThanTwoWeeks(date) && { backgroundColor: '#ffbf00' }),
        ...(isServerFetched && date && isDateInFuture(date) && { backgroundColor: '#ADD8E6' }),
    };

    return (
        <td className={`table-suggested-date${date ? ' defined' : ''}`} style={cellStyle}>
            {date ? date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', weekday: 'short' }) : '-'}
        </td>
    );
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {month: 'short', day: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);
    const today = new Date();
    const timeDifference = today - date;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const finalFormattedDate = `${formattedDate} (-${daysDifference} days ago)`;
    return finalFormattedDate;
};

export const TableRow = ({lead, index, handleDeleteRow, handleChange, template, outreachCount}) => {
    const [responseStatusDetails, setResponseStatusDetails] = useState(undefined);
    const [responseStatusColor, setResponseStatusColor] = useState('');
    const [responseStatus, setResponseStatus] = useState(undefined);
    const [coldDate, setColdDate] = useState(null);
    const [warmDate, setWarmDate] = useState(null);
    const [followUpDate, setFollowUpDate] = useState(null);
    const [byeDate, setByeDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [addedToStopList, setAddedToStopList] = useState(false)
    const [emailInSpamDb, setEmailInSpamDb] = useState(false);
    const [inputValue, setInputValue] = useState(lead.email);

    const debounceTimeoutRef = useRef(null); // Move debounceTimeoutRef to the top

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const validateEmail = (email) => {
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
        return regex.test(email);
    };

    const fetchEmailStatus = async () => {
        let color = null
        let responseStatusDetails_temp = 'not outreached'
        let responseStatus_temp = null

        try {
            let emailStatus;
            if (validateEmail(lead.email)) {
                emailStatus = await getEmailStatus(lead.email, template, lead.company, outreachCount, addedToStopList);
                const isEmailInSpamDb = await checkEmailInSpam(lead.email)
                setEmailInSpamDb(isEmailInSpamDb);
            }

            if (emailStatus !== undefined) {
                const { sent, opened, replied, inStoplist } = emailStatus;

                if (sent.status) {
                    color = '#b2b2e9';
                    responseStatusDetails_temp = `sent on ${formatDate(sent.date)}`;
                }
                if (opened.status) {
                    color = '#cdcd54';
                    responseStatusDetails_temp = `read on ${formatDate(opened.date)}`;
                }
                if (replied.status.regular) {
                    color = 'green';
                    responseStatusDetails_temp = `replied on ${formatDate(replied.date)}`;
                }
                if (replied.status.outOfOffice) {
                    color = 'black';
                    responseStatusDetails_temp = `out-of-office on ${formatDate(replied.date)}`;
                }
                if (replied.status.bounced) {
                    color = 'pink';
                    responseStatusDetails_temp = `not delivered on ${formatDate(replied.date)}`;
                }
                if (replied.status.forwarded) {
                    color = '#98FB98';
                    responseStatusDetails_temp = `forwarded on ${formatDate(replied.date)}`;
                }
                if (inStoplist.status) {
                    color = '#be5656';
                    responseStatusDetails_temp = `in stoplist since ${formatDate(inStoplist.date)}`;
                    responseStatus_temp = 'STOPLIST';
                }
            }
        } catch (error) {
            console.error('Error fetching email status:', error);
        } finally {
            setResponseStatus(responseStatus_temp);
            setResponseStatusDetails(responseStatusDetails_temp);
            setResponseStatusColor(color);
        }
    };

    const computeTemplateSchedule = async () => {
        const coldDateFromServer = await getEmailDate(lead.email, 'cold', outreachCount);
        if (!coldDateFromServer) {
            setColdDate(null);
            setWarmDate(null);
            setFollowUpDate(null);
            setByeDate(null);
            return;
        }
        setColdDate({ date: coldDateFromServer, source: 'server' });

        const warmDateFromServer = await getEmailDate(lead.email, 'warm', outreachCount);
        let date = warmDateFromServer ? warmDateFromServer : addBusinessDays(coldDateFromServer, 2);
        let source = warmDateFromServer ? 'server' : 'suggested';
        setWarmDate({ date, source });

        const followUpDateFromServer = await getEmailDate(lead.email, 'followup', outreachCount);
        date = followUpDateFromServer ? followUpDateFromServer : addBusinessDays(date, 2);
        source = followUpDateFromServer ? 'server' : 'suggested';
        setFollowUpDate({ date, source });

        const byeDateFromServer = await getEmailDate(lead.email, 'bye', outreachCount);
        date = byeDateFromServer ? byeDateFromServer : addBusinessDays(date, 3);
        source = byeDateFromServer ? 'server' : 'suggested';
        setByeDate({ date, source });
    };

    useEffect(() => {
        fetchEmailStatus();
        computeTemplateSchedule();
    }, [lead.email, template, lead.company, outreachCount, addedToStopList]);

    const translateEmailingStatusColor = (emailingStatus) => {
        switch (emailingStatus) {
            case 'OK':
                return 'green';
            case 'FAILED':
            case 'STOPLIST':
                return 'red';
            case 'SKIPPED':
                return 'blue';
            default:
                return 'transparent';
        }
    };

    const handleToStoplist = async () => {
        openModal();
    };

    const confirmToStoplist = async () => {
        closeModal();
        try {
            const success = await addToStoplist(lead.email, template, lead.company);
            if (success) {
                setTimeout(() => {
                    setAddedToStopList(true);
                }, 5000);
            }
        } catch (error) {
            console.error(`Error adding ${lead.email} to stoplist:`, error);
        }
    };

    const hideTooltipAfterDelay = () => {
        setTimeout(() => {
            setTooltipVisible(false);
            setTooltipContent('');
        }, 2000);
    };

    useEffect(() => {
        setInputValue(lead.email);
    }, [lead.email]);

    const debouncedHandleChange = useCallback((event, index, field) => {
        // Update the local state immediately so the input updates as the user types
        setInputValue(event.target.value);

        // Clear the existing timeout if it exists
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new debounce timeout (3 seconds)
        debounceTimeoutRef.current = setTimeout(() => {
            // Call the handleChange function after the user stops typing
            handleChange(event, index, field);
        }, 2000);

    }, [handleChange]);

    return (
        <tr key={index}>
            <td className="table-id">
                <span className="delete-icon" onClick={() => handleDeleteRow(index)}>
                    <RiDeleteBin6Line />
                </span>
            </td>
            <td className="table-id">
                <span className="row-id">{index + 1}</span>
            </td>
            <td className="table-response-status" style={{ width: '10px' }} title={`${responseStatusDetails}`}>
                {responseStatusColor && <div className="semaphore" style={{ backgroundColor: responseStatusColor }}></div>}
            </td>
            <td>
                <input
                    placeholder="Name"
                    className="input-item-email"
                    style={{ width: '90%' }}
                    value={lead.name}
                    onChange={(event) => handleChange(event, index, 'name')}
                />
            </td>
            <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        placeholder="Company"
                        className="input-item-email"
                        style={{ width: '90%' }}
                        value={lead.company}
                        onChange={(event) => handleChange(event, index, 'company')}
                    />
                    {lead.jobs && (
                        <span
                            className="jobs-indicator"
                            onClick={() => {
                                setTooltipVisible(!tooltipVisible);
                                setTooltipContent(doRenderJobsTooltip(lead.jobs));
                            }}
                        ></span>
                    )}
                    {tooltipVisible && (
                        <div className="tooltip-jobs">
                            <div style={{ position: 'absolute', right: '5px', top: '0', cursor: 'pointer' }}
                                 onClick={() => setTooltipVisible(false)}>×
                            </div>
                            {tooltipContent}
                        </div>
                    )}
                </div>
            </td>
            <td style={{ position: 'relative', backgroundColor: emailInSpamDb ? '#f8d7da' : 'transparent' }}>
                <input
                    placeholder="Email"
                    className="input-item-email"
                    style={{ width: 'calc(100% - 20px)' }}
                    value={inputValue} // Bind to local state
                    onChange={(event) => debouncedHandleChange(event, index, 'email')}
                />
                <span
                    style={{
                        position: 'absolute',
                        right: '5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                    onClick={() => {
                        navigator.clipboard.writeText(lead.email);
                    }}
                >
                    <RiFileCopyLine />
                </span>
            </td>
            <td>
                <select
                    placeholder="Role"
                    className="input-item-role"
                    style={{ width: '90%' }}
                    value={lead.role || ""}
                    onChange={(event) => handleChange(event, index, 'role')}
                >
                    <option value="">Select Role</option>
                    <option value="Founder">Founder</option>
                    <option value="CTO">CTO</option>
                    <option value="CEO">CEO</option>
                    <option value="Manager">Manager</option>
                    <option value="HR/Recruiter">HR/Recruiter</option>
                    <option value="C Suite">C Suite</option>
                </select>
            </td>
            <td className="table-status" style={{ backgroundColor: translateEmailingStatusColor(lead.status) }}>
                {lead.status}
            </td>
            <SuggestedDateCell date={coldDate && coldDate.date} isServerFetched={coldDate && coldDate.source === 'server'} />
            <SuggestedDateCell date={warmDate && warmDate.date} isServerFetched={warmDate && warmDate.source === 'server'} />
            <SuggestedDateCell date={followUpDate && followUpDate.date} isServerFetched={followUpDate && followUpDate.source === 'server'} />
            <SuggestedDateCell date={byeDate && byeDate.date} isServerFetched={byeDate && byeDate.source === 'server'} />

            <td className="table-stoplist">
                {responseStatus !== 'STOPLIST' && (
                    <>
                        <span className="stoplist-icon" onClick={handleToStoplist} title="Add to Stoplist">S</span>
                        <ConfirmModal
                            isOpen={showModal}
                            onClose={closeModal}
                            onConfirm={confirmToStoplist}
                        />
                    </>
                )}
            </td>
        </tr>
    );
};
