import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import JobTable from './JobTable'; 
import './home.css';
import {
    lookupJobsIndeed, lookupJobsUpwork,
    lookupOrg,
    lookupPoc,
    lookupPocDetails
} from "../../services/lookup";
import { downloadJsonAsXl } from "../../utils/downloadXl";

const Home = () => {
    const [keywords, setKeywords] = useState('');
    const [city, setCity] = useState('United States');
    const [source, setSource] = useState('Upwork');
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [count, setCount] = useState(1);

    const fetchJobs = async () => {
        setLoading(true);
        let openJobs = [];
    
        try {
            switch (source) {
                case 'Indeed':
                    openJobs = await lookupJobsIndeed(keywords, city);
                    break;
                case 'Upwork':
                    openJobs = await lookupJobsUpwork(keywords, city, count);
                    break;
                default:
                    console.error('Invalid source:', source);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error.response.data.error);
            alert(error.response.data.error);
        }
    
        setLoading(false);
    
        return openJobs;
    };
    
    const saveJobsToXl = async (openJobs) => {
        const resultData = [];
    
        for (const openJob of openJobs) {
            try {
                const companyName = openJob.company || openJob.companyName;
                const orgIds = await lookupOrg(companyName);
    
                if (!orgIds || orgIds.length === 0) {
                    throw new Error(`No org found`);
                }
    
                const response =
                    await lookupPoc(orgIds, 'recruiter') ||
                    await lookupPoc(orgIds, 'talent acquisition manager') ||
                    await lookupPoc(orgIds, 'talent');
    
                if (!response || response.length === 0) {
                    throw new Error(`No recruiter at ${companyName}`);
                }
    
                const poc = response;
                const pocDetail = await lookupPocDetails(poc.person_id || poc.id);
    
                const processedJob = {
                    name: pocDetail.name || poc.name,
                    email: pocDetail.email || poc.email,
                    role: 'HR/Recruiter',
                    title: pocDetail.title || poc.title,
                    company: companyName,
                    companyId: pocDetail.organization_id || poc.organization_id,
                    jobs: `${openJob.positionName}: ${openJob.url}: ${openJob.location}`
                };
    
                if (processedJob.email) {
                    resultData.push(processedJob);
                }
                
                await downloadJsonAsXl(resultData);
                alert(`Excel has been downloaded with ${resultData.length} leads`);
            } catch (error) {
                console.error('Error processing job:', error);
            }
        }
    };
    
    const handleFindJobs = async () => {
       const openJobs = await fetchJobs();
       switch (source) {
            case 'Indeed':
                await saveJobsToXl(openJobs);
                break;
            case 'Upwork':
                setJobs(openJobs);
                break;
            default:
                console.error('Invalid source:', source);
                break;
        }
    };

    return (
        <div className="home-container">
            <div className="two-column-layout">
                <div className="column">
                    <h3 className="column-heading">Find jobs</h3>
                    <div className="input-group">
                        <label htmlFor="keywords">Keywords</label>
                        <input
                            type="text"
                            id="keywords"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="source">Source</label>
                        <select
                            id="source"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        >
                            <option value="Indeed">Indeed</option>
                            <option value="Upwork">Upwork</option>
                        </select>
                    </div>
                    {/* Step 1: Dropdown for count */}
                    <div className="input-group">
                        <label htmlFor="count">Count</label>
                        <select
                            id="count"
                            value={count}
                            onChange={(e) => setCount(parseInt(e.target.value))}
                        >
                            {[1, 5, 10, 20, 50, 100].map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <Button onClick={handleFindJobs} style={{ marginLeft: '0px' }}>
                        Submit
                    </Button>
                </div>

                {/* Divider */}
                <div className="divider"></div>

                {/* Loading, Error, and Result Sections */}
                {loading && (
                    <div className="loading-spinner-overlay fullscreen">
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>

            {/* Render JobTable if jobs exist */}
            {jobs && jobs.length > 0 && (
                <div className="jobs-container">
                    <h1>Jobs Available</h1>
                    <JobTable jobs={jobs} />
                </div>
            )}
        </div>
    );
};

export default Home;
