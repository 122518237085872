// Named constants for localStorage keys
const KEY_USERNAME = 'altareach-username';
const KEY_PASSWORD = 'altareach-password';
const KEY_EMAIL = 'altareach-email';

export function getGlobalUser() {
    return localStorage.getItem(KEY_USERNAME);
}

export function setGlobalUser(username) {
    localStorage.setItem(KEY_USERNAME, username);
}

export function getGlobalPassword() {
    return localStorage.getItem(KEY_PASSWORD);
}

export function setGlobalPassword(password) {
    localStorage.setItem(KEY_PASSWORD, password);
}

export function getGlobalEmail() {
    return localStorage.getItem(KEY_EMAIL);
}

export function setGlobalEmail(email) {
    localStorage.setItem(KEY_EMAIL, email);
}