export const bye_template_pug = `doctype html
html
  head
    block head
      meta(charset="utf-8")
      meta(http-equiv="X-UA-Compatible", content="IE=edge")
      meta(name="x-apple-disable-message-reformatting")
      style.
        a {
          font-size: 13px;
          color: #0000AA;
        }
        p, ul, li {
          margin: 0;
          font-family: "Apto Thin", sans-serif;
          font-size: 13px;
          color: #505050;
        }
        .paragraph-bottom-spacing {
          margin-bottom: 13px;
        }
        .italic {
          font-style: italic;
        }
        table {
          width: 100%;
          font-size: 11px;
          font-family: "Apto Thin", sans-serif;
        }
  body
    p(class="paragraph-bottom-spacing") Hey #{name},
    p(class="paragraph-bottom-spacing") At this point I believe that reducing search time and expanding your candidates pipeline are not the priorities.
    p(class="paragraph-bottom-spacing") Anyway, if that changes feel free to contact me for collaboration. Cheers! 
    p(class="paragraph-bottom-spacing")
      each value, key in signature
        p=value
    p(class="paragraph-bottom-spacing italic") We respect your privacy. If not interested in this offer, you may add yourself to the stoplist 
      a(href=unsubscribeLink, target="_blank") here
      | .
    p(class="paragraph-bottom-spacing italic") This is a promotional email of a product or service offering by Altarey located at 1201 N Orange St #7643, Wilmington, DE 19801.  
    img(src=trackingPixel alt="" width="1" height="1")
`