import React from "react";

/* example
Senior Technical Program / Delivery Manager: https://www.linkedin.com/jobs/view/senior-technical-program-delivery-manager-at-inrhythm-3803013629?refId=%2FYBIJvi1qKYs%2FN33w1wK1w%3D%3D&trackingId=8kImLjN5syNN0uNdXo9QrQ%3D%3D&position=18&pageNum=7&trk=public_jobs_jserp-result_search-card; Senior Technical Program / Delivery Manager: https://www.linkedin.com/jobs/view/senior-technical-program-delivery-manager-at-inrhythm-3814288796?refId=Sm1NUFj5MS0mNnIBFBFddQ%3D%3D&trackingId=MPz32jSlwdRyHq9Ikq9HNA%3D%3D&position=25&pageNum=23&trk=public_jobs_jserp-result_search-card; Engineering Manager - Java: https://www.linkedin.com/jobs/view/engineering-manager-java-at-inrhythm-3809656342?refId=E7BEky72oT7J668GRXbWTw%3D%3D&trackingId=SCf5IziMNByhwetQC0OmUQ%3D%3D&position=12&pageNum=24&trk=public_jobs_jserp-result_search-card;*/

export function doRenderJobsTooltip(jobs) {
  const render = () => {
    if (!jobs) {
      return null;
    }

    const jobArray = jobs.split("; ").map((job) => job.split(": "))
    
    const content = (
     <div>
      <strong>Open jobs ({jobArray.length})</strong>
      <ul>
        {jobArray.map((job, index) => (
          <li key={index}>
            <a href={job[1]} target="_blank" rel="noopener noreferrer">
              {job[0]}
            </a>
            {' '}
            {job[2].replace(/;+$/, '')}
          </li>
        ))}
      </ul>
     </div>
    )

    return content
  }

  return render
}
