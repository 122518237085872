// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    display: flex;
    width: 76px;
    background-color: #EBEBEB;
    top: 0;
    transition: left 0.3s ease-in-out;
    z-index: 99;
    align-items: center;
    flex-direction: column;
    transition: 0.4s ease all;
}

.sidebar.open {
    width: 76px;
}

.toggle-button {
    background-color: #444444;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 76px;
    height: 76px;
}

.sidebar-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;

    a {
        text-decoration: none;
        color: #000;
    }
}

.active-page {
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
}

.sidebar-element {
    padding: 10px;
}

.sidebar-element:hover {
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,MAAM;IACN,iCAAiC;IACjC,WAAW;IACX,mBAAmB;IACnB,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;;IAEhB;QACI,qBAAqB;QACrB,WAAW;IACf;AACJ;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".sidebar {\n    display: flex;\n    width: 76px;\n    background-color: #EBEBEB;\n    top: 0;\n    transition: left 0.3s ease-in-out;\n    z-index: 99;\n    align-items: center;\n    flex-direction: column;\n    transition: 0.4s ease all;\n}\n\n.sidebar.open {\n    width: 76px;\n}\n\n.toggle-button {\n    background-color: #444444;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n    width: 76px;\n    height: 76px;\n}\n\n.sidebar-content {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-top: 40px;\n\n    a {\n        text-decoration: none;\n        color: #000;\n    }\n}\n\n.active-page {\n    background-color: #FFF;\n    border-radius: 10px;\n    padding: 10px;\n}\n\n.sidebar-element {\n    padding: 10px;\n}\n\n.sidebar-element:hover {\n    background-color: #FFF;\n    border-radius: 10px;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
