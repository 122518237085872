import React from 'react'

const EmailSectionVisualize = ({ html }) => {
    return (
        <div className="email-section">
            <h3>Email</h3>
            <div className="email-preview" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default EmailSectionVisualize