import {getApiUrl} from "../utils/getApiUrl";
import logger from "../utils/logger";
import {getUserEmail, getUserName} from "../utils/getUserName";
import {axiosGetWithUserId} from "../utils/apiCalls";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function compilePug(pugCode, templateNameDefault, templateNameCustom, companyName='SomeCompany') {
    const email = await getUserEmail()
    const name = capitalizeFirstLetter(await getUserName())

    const apiUrl = getApiUrl();

    try {
        const response = await axiosGetWithUserId(`${apiUrl}/pug_compile`, {
            params: {
                pugCode,
                templateNameDefault,
                templateNameCustom,
                email,
                name,
                companyName,
            },
        });

        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        logger.error(error);
        if (error.response && error.response.data && error.response.data.error) {
            return `<p>Error: ${error.response.data.error}</p>`;
        } else {
            return `<p>Unknown error occurred</p>`;
        }
    }
}