import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import Modal from 'react-modal';
import PasswordModal from "./components/PasswordModal/PasswordModal";
import logger from './utils/logger';
import {checkPassword} from "./services/checkPassword";
import {getGlobalPassword, getGlobalUser, setGlobalPassword, setGlobalUser} from "./utils/globals";

// Set the root element for the modal
Modal.setAppElement('#root');
const rootElement = document.getElementById('root');

const MainApp = () => {
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handlePasswordSubmit = async (enteredUsername, enteredPassword, isFirstSubmit = false) => {
        try {
            setGlobalUser(enteredUsername);
            const isValid = await checkPassword(enteredPassword);
            if (isValid) {
                setUsername(enteredUsername);
                setGlobalPassword(enteredPassword)
                setPasswordModalOpen(false);
                setIsAuthenticated(true);
                logger.log(`Password validated successfully for ${enteredUsername}`);
            } else if (!isFirstSubmit) {
                throw new Error('Invalid username or password');
            }
        } catch (error) {
            logger.error('Error during password submission:', error);
            if (!isFirstSubmit) alert(error.message);
        }
    };

    useEffect(() => {
        const storedPassword = getGlobalPassword();
        const storedUsername = getGlobalUser();

        if (storedUsername && storedPassword) {
            handlePasswordSubmit(storedUsername, storedPassword, true);
        } else {
            setPasswordModalOpen(true);
        }
    }, []);

    return (
        <>
            <BrowserRouter>
                {(isAuthenticated || !!process.env.REACT_APP_CHECK_PASSWORD) && <App username={username} />}
            </BrowserRouter>
            <PasswordModal
                isOpen={isPasswordModalOpen}
                onPasswordSubmit={handlePasswordSubmit}
            />
        </>
    );
};

ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
        <MainApp />
    </React.StrictMode>
);