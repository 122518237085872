import {getApiUrl} from "../utils/getApiUrl";
import pMemoize from "p-memoize";
import {axiosGetWithUserId, axiosPostWithUserId} from "../utils/apiCalls";

export const lookupPocDetails = pMemoize(
    async (id) => {
        try {
            const pocDetails = await axiosGetWithUserId(`${getApiUrl()}/lookup_poc_details`, {
                params: {
                    id,
                },
            });
    
            if (pocDetails.status === 200) {
                return pocDetails.data
            }
        } catch (error) {
            console.error("An error occurred during the API call:", error);
            return [];
        }
    },
    {
        cacheKey: (args) => `${args[0]}`,
        maxAge: 14 * (24 * 60 * 60 * 1000), // 14 days
    }
)

export const lookupPoc = pMemoize(
    async (companyIds, personasType) => {
        try {
            const pocResponse = await axiosGetWithUserId(`${getApiUrl()}/lookup_poc`, {
                params: {
                    companyIds: companyIds.join(','),
                    personasType: personasType,
                },
            });

            if (pocResponse.status === 200) {
                return pocResponse.data && pocResponse.data.length > 0 && pocResponse.data[0] 
                    ? pocResponse.data[0]  
                    : null
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    },
    {
        cacheKey: (args) => `${args[0]}:${args[1]}`,
        maxAge: 14 * (24 * 60 * 60 * 1000), // 14 days
    }
)

export const lookupOrg = pMemoize(
    async (companyName, keywords, locations, orgNumEmployeesRanges) => {
        try {
            let response = await axiosGetWithUserId(`${getApiUrl()}/lookup_org`, {
                params: {
                    companyName,
                    keywords,
                    locations,
                    orgNumEmployeesRanges
                },
            });

            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            const orgData = response.data;
            return orgData
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    },
    {
        cacheKey: (args) => `${args[0]}:${args[1]}:${args[2]}:${args[3]}`,
        maxAge: 14 * (24 * 60 * 60 * 1000), // 14 days
    }
)

export const lookupOrgJobs = pMemoize(
    async (companyId) => {
        try {
            let response = await axiosGetWithUserId(`${getApiUrl()}/lookup_org/jobs`, {
                params: {
                    companyId,
                },
            });

            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            const orgData = response.data;
            return orgData
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    },
    {
        cacheKey: (args) => `${args[0]}`,
        maxAge: 14 * (24 * 60 * 60 * 1000), // 14 days
    }
)

export const lookupStatus = async () => {
    try {
        let response = await axiosGetWithUserId(`${getApiUrl()}/lookup_status`);

        if (response.status !== 200) {
            throw new Error(response.statusText);
        }

        const orgData = response.data;
        return orgData
    } catch (error) {
        console.error(`Error: ${error}`);
    }
}

export const lookupJobsIndeed = pMemoize(
    async (keywords, location) => {
        
        if (!location) {
            location = 'United States'
        }
        try {
            let response = await axiosGetWithUserId(`${getApiUrl()}/lookup_org/jobs/search/indeed`, {
                params: {
                    location,
                    keywords
                },
            });

            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            const orgData = response.data;
            return orgData
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    },
    {
        cacheKey: (args) => `${args[0]}`,
        maxAge: 14 * (24 * 60 * 60 * 1000), // 14 days
    }
)

export const lookupJobsUpwork = pMemoize(
    async (keywords, location, count) => {
        
        if (!location) {
            location = 'United States'
        }
        let response = await axiosGetWithUserId(`${getApiUrl()}/lookup_org/jobs/search/upwork`, {
            params: {
                location,
                keywords,
                maxItems: count,
            },
            timeout: 10 * 60 * 1000, // Set timeout to 10 minutes
        });

        if (response.status !== 200) {
            throw new Error(response.statusText);
        }

        const orgData = response.data;
        return orgData
    },
    {
        cacheKey: (args) => `${args[0]}:${args[1]}:${args[2]}`,
        maxAge: 1 * (24 * 60 * 60 * 1000), // 1 day
    }
)

export const applyJobsUpwork = async ({ useAgency, useFreelancer, attachmentUrl, coverLetter, jobUrl }) => {
    const apiUrl = `${getApiUrl()}/lookup_org/jobs/search/upwork`;

    try {
        const response = await axiosPostWithUserId(apiUrl, null, {
            params: {
                useAgency,
                useFreelancer,
                attachmentUrl,
                coverLetter,
                jobUrl
            }
        });

        if (response.status !== 200) {
            throw new Error(response.statusText);
        }

        return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error; // Rethrow the error for handling in the calling code
    }
};

