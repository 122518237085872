import {isSameDay} from "date-fns";
import React from "react";
import './styles.css';

export function doRenderScheduledTooltip(scheduledOutreaches) {
    const renderTaskTooltip = (date) => {
        const tasksForDate = scheduledOutreaches && scheduledOutreaches.filter((task) => {
            const taskDate = new Date(task.time);
            const inputDate = new Date(date);

            // Extract the date parts for comparison
            const taskOnlyDate = new Date(taskDate.getFullYear(), taskDate.getMonth(), taskDate.getDate());
            const inputOnlyDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

            // Check if the dates are the same
            return isSameDay(taskOnlyDate, inputOnlyDate);
        });

      if (tasksForDate && tasksForDate.length > 0) {
          // Extract unique campaign titles and their associated templates
          const uniqueCampaigns = tasksForDate.reduce((acc, task) => {
            if (!acc[task.query.campaignTitle]) {
              acc[task.query.campaignTitle] = task.query.template;
            }
            return acc;
          }, {});
        
          const content = (
            <div className="taskPopup">
              {Object.entries(uniqueCampaigns).map(([title, template], index) => (
                <div key={index}>
                  <strong>{title}</strong> ({template})
                </div>
              ))}
            </div>
          );
        
          return content;
        }

        return null;
    };
    return renderTaskTooltip;
}