// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pug-converter-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    padding: 20px;
    margin: 0 10px;
    border-radius: 12px;
}

.select-label {
    margin-right: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-column > * { /* Target all direct children of .flex-row */
  height: 500px; /* Set a fixed height for each flex item */
}

.editor-section {
    width: 98%;
    height: 350px;
    gap: 5px;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
    margin-left: 8px;
}

.email-section {
    width: 97%;
    max-height: 500px;
    gap: 10px;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
    padding-left: 10px;
    margin-left: 8px;
}

.email-preview {
    text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/pages/EmailTemplate/PugConverter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,sCAAsC;IACtC,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA,mBAAmB,4CAA4C;EAC7D,aAAa,EAAE,0CAA0C;AAC3D;;AAEA;IACI,UAAU;IACV,aAAa;IACb,QAAQ;IACR,uCAAuC;IACvC,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,SAAS;IACT,uCAAuC;IACvC,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".pug-converter-container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);\n    padding: 20px;\n    margin: 0 10px;\n    border-radius: 12px;\n}\n\n.select-label {\n    margin-right: 10px;\n}\n\n.flex-column {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.flex-column > * { /* Target all direct children of .flex-row */\n  height: 500px; /* Set a fixed height for each flex item */\n}\n\n.editor-section {\n    width: 98%;\n    height: 350px;\n    gap: 5px;\n    box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);\n    margin-left: 8px;\n}\n\n.email-section {\n    width: 97%;\n    max-height: 500px;\n    gap: 10px;\n    box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);\n    padding-left: 10px;\n    margin-left: 8px;\n}\n\n.email-preview {\n    text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
