import React, { useState } from 'react';

const TemplateTabs = ({
  tabs,
  setTabs,
  activeTab,
  setActiveTab,
  handleChangeTabTitle,
  isEditAvailable,
  setCode,
  notEditableTabsIds
}) => {
    const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query

    const handleDeleteTab = (tabId) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete '${activeTab.title}' tab?`);
        if (confirmDelete) {
            setTabs((prevTabs) => {
                const newTabs = prevTabs.filter((tab) => tab.id !== tabId);
                if (newTabs.length > 0) {
                    const lastTabAfterDeleted = newTabs.length - 1;
                    setActiveTab(newTabs[lastTabAfterDeleted]);
                    setCode(newTabs[lastTabAfterDeleted].code);
                }
                return newTabs;
            });
        }
    };

    const chooseTab = (tabId) => {
        const selectedTab = tabs.find((tab) => tab.id === tabId);
        setActiveTab(selectedTab);
        setCode(selectedTab.code);
    };

    // Sort tabs by title alphabetically
    const sortedTabs = [...tabs].sort((a, b) => a.title.localeCompare(b.title));

    // Filter tabs based on the search query
    const filteredTabs = sortedTabs.filter(tab =>
        tab.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            marginLeft: '0px'
        }}>
            {/* Search box to filter tabs */}
            <input
                type="text"
                placeholder="Search tabs..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                    padding: '8px',
                    marginBottom: '10px',
                    width: '100%',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                }}
            />

            <select
                value={activeTab.id}
                onChange={(e) => chooseTab(parseInt(e.target.value))}
                style={{
                    padding: '8px',
                    backgroundColor: '#DDD',
                    color: '#000',
                    borderRadius: '4px',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                {filteredTabs.map(tab => (
                    <option key={tab.id} value={tab.id}>
                        {tab.title}
                    </option>
                ))}
            </select>

            {!notEditableTabsIds.includes(activeTab.id) && (
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <span onClick={() => handleChangeTabTitle(activeTab.id)}>✎</span>
                    <span onClick={() => handleDeleteTab(activeTab.id)}>🗑️</span>
                    {activeTab.id > 4 && <span style={{ fontSize: 8, marginLeft: '4px', color: 'white' }}>{activeTab.template}</span>}
                </div>
            )}
        </div>
    );
};

export default TemplateTabs;
