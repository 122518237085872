export const followup_template_pug = `doctype html
html
  head
    block head
      meta(charset="utf-8")
      meta(http-equiv="X-UA-Compatible", content="IE=edge")
      meta(name="x-apple-disable-message-reformatting")
      style.
        a {
          font-size: 13px;
          color: #0000AA;
        }
        p, ul, li {
          margin: 0;
          font-family: "Apto Thin", sans-serif;
          font-size: 13px;
          color: #505050;
        }
        .paragraph-bottom-spacing {
          margin-bottom: 13px;
        }
        .italic {
          font-style: italic;
        }
        table {
          width: 100%;
          font-size: 13px;
          font-family: "Apto Thin", sans-serif;
        }
  body
    p(class="paragraph-bottom-spacing") Hi #{name},
    p(class="paragraph-bottom-spacing") I hope my email will reach your attention. It’s not just a follow-up.
    p(class="paragraph-bottom-spacing") This time I'd like to introduce two candidates: meet Vlad and Javier. They're skilled Java and JS developers, who went through our comprehensive tech assessment. Their resumes and score are attached for reference.
    table(style='width:100%', border='1')
      thead
        tr
          th Name
          th Status
          th Location
          th Notes
      tbody
        tr
          td Vlad
          td C-19/EAD Ukrainian refugee
          td West Coast, open for relocation
          td Published multiple Java-focused articles
        tr
          td Javier
          td USC
          td Tampa, FL
          td Works at Citi, ex employer JPM
    p(class="paragraph-bottom-spacing")
    p(class="paragraph-bottom-spacing") We're focused on providing only qualified candidates who match up our customers’ expectations. Again: based on our expertise, network and software aid.
    p(class="paragraph-bottom-spacing") Would that be an interesting offer to you? I'd be glad to discuss your current needs searching for new IT talents with you. Or perhaps, advise other point-of-contact at your organization.
    p(class="paragraph-bottom-spacing")
      each value, key in signature
        p=value
    p(class="paragraph-bottom-spacing italic") We respect your privacy. If not interested in this offer, you may add yourself to the stoplist 
      a(href=unsubscribeLink, target="_blank") here
      | .
    p(class="paragraph-bottom-spacing italic") This is a promotional email of a product or service offering by Altarey located at 1201 N Orange St #7643, Wilmington, DE 19801.  
    img(src=trackingPixel alt="" width="1" height="1")
`