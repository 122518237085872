const usHolidays = [
    "2024-01-01", // New Year's Day
    "2024-01-15", // Martin Luther King Jr. Day
    "2024-02-19", // Presidents' Day
    "2024-05-27", // Memorial Day
    "2024-07-04", // Independence Day
    "2024-09-02", // Labor Day
    "2024-10-14", // Columbus Day
    "2024-11-11", // Veterans Day
    "2024-11-28", // Thanksgiving Day
    "2024-12-25", // Christmas Day
    "2024-04-01", // April Fools' Day
    "2024-10-31", // Halloween
    "2024-12-31"  // New Year's Eve
    // Add more holidays as needed
];

// Function to check if a date is a US holiday (example)
export const isUSHoliday = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return usHolidays.includes(formattedDate);
};