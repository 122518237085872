import {useEffect, useState} from 'react'
import {useEmailContext} from '../../context/emailContext';
import {emailTemplates} from "./EmailTemplates";
import {randomUID} from '../../utils/generateRandomUid';
import {sendMassEmail} from '../../services/sendMassEmail'
import {getUserEmail, getUserName} from "../../utils/getUserName";
import {compilePug} from "../../services/compilePug";

const useEmailTemplate = () => {
    const {
        emailTemplate,
        setEmailTemplate,
        defaultTemplates,
    } = useEmailContext()

    const [code, setCode] = useState(emailTemplates.cold);
    const [html, setHtml] = useState("")
    const [newModalTemplateVisible, setNewModalTemplateVisible] = useState(false)
    const [activeTab, setActiveTab] = useState(defaultTemplates[0]);
    const [tabs, setTabs] = useState(defaultTemplates)

    const notEditableTabsIds = [1, 2, 3, 4]
    const isEditAvailable = !notEditableTabsIds.includes(activeTab.id)
    useEffect(() => {
        loadWorkspace(setTabs);
    }, [])

    function loadWorkspace() {
        const savedTemplates = localStorage.getItem('templateTabs');
        if (savedTemplates) {
            const savedTemplatesJson = JSON.parse(savedTemplates);
            setTabs(defaultTemplates.concat(savedTemplatesJson));
        }
    }
    
    function saveWorkspace() {
        const customTemplates = tabs.filter(
                tab => !["Cold", "Warm", "FollowUp", "Bye"].includes(tab.title));
        localStorage.setItem('templateTabs', JSON.stringify(customTemplates))
    }
    
    const handleChangeTemplate = (evn) => {
        const updatedCode = evn.target.value;
        setActiveTab((prevActiveTab) => ({
            ...prevActiveTab,
            code: updatedCode,
        }));
        setCode(updatedCode);
    };

    const handleChangeTabTitle = (tabId) => {
        if (!isEditAvailable) return;
        const promptTitle = prompt('Enter new title', activeTab.title);
        if (promptTitle) {
            setTabs((prevTabs) => {
                const updatedTabs = prevTabs.map((tab) =>
                    tab.id === tabId ? { ...tab, title: promptTitle } : tab
                );
                return updatedTabs;
            });
        }
    };

    const handleCompilePug = async () => {
        const convertedHtml = await compilePug(code, activeTab.template, activeTab.title);
        await setHtml(convertedHtml)
        updateHtmlInTab(convertedHtml)
    };

    const openNewTemplateModal = () => {
        setNewModalTemplateVisible(true)
    }

    const closeNewTemplateModal = () => {
        setNewModalTemplateVisible(false)
    }

    const createNewTemplate = (templateType) => {
        setEmailTemplate(templateType)
        setCode(emailTemplates[templateType])
        const newTab = {
            id: tabs.length + 1,
            title: `New ${randomUID()} ${templateType}`,
            template: templateType,
            code: emailTemplates[templateType]
        }
        setTabs(previousTabs => [...previousTabs, newTab])
        setActiveTab(newTab)
        closeNewTemplateModal()
    }

    const handleSaveTemplateStorage = () => {
        saveWorkspace(tabs);
        alert('Templates saved successfully')
    }

    const updateCodeInTab = () => {
        setTabs((prevTabs) => {
            const index = prevTabs.findIndex((tab) => tab.id === activeTab.id);
            if (index !== -1) {
                const updatedTabs = [...prevTabs];
                updatedTabs[index] = { ...updatedTabs[index], code };
                return updatedTabs;
            }
            return prevTabs;
        });
    };
    
    const updateHtmlInTab = (html) => {
        setTabs((prevTabs) => {
            const index = prevTabs.findIndex((tab) => tab.id === activeTab.id);
            if (index !== -1) {
                const updatedTabs = [...prevTabs];
                updatedTabs[index] = { ...updatedTabs[index], html };
                return updatedTabs;
            }
            return prevTabs;
        });
    };
    
    const handleSendEmail = async () => {
        const name = await getUserName()
        const email = await getUserEmail()
        
        const result = await sendMassEmail(
            'SELF EMAIL (TEST)',
            activeTab.template,
            [{
                name,
                company: 'Altarey',
                role: 'Manager',
                email,
            }],
            undefined,
            undefined,
            false,
            code,
            activeTab.title,
        )
        let alertMessage = `Email has been sent!`
        if (result.status !== 'OK') {
            alertMessage = `Error occurred ${result.message}`
        } 
        alert(alertMessage)
    }

    useEffect(() => {
        updateCodeInTab()
    }, [code, activeTab.id, html])

    useEffect(() => {
        handleCompilePug()
    }, [code, emailTemplate, activeTab.id, activeTab.code, activeTab.template, activeTab.title])

    return {
        handleChangeTemplate,
        openNewTemplateModal,
        closeNewTemplateModal,
        tabs,
        setTabs,
        activeTab,
        setActiveTab,
        setCode,
        html,
        newModalTemplateVisible,
        code,
        handleCompilePug,
        emailTemplate,
        createNewTemplate,
        isEditAvailable,
        handleChangeTabTitle,
        handleSaveTemplateStorage,
        updateCodeInTab,
        notEditableTabsIds,
        handleSendEmail
    }
}

export default useEmailTemplate