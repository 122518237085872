import {cold_template_pug} from "./templates/cold_template_pug";
import {warm_template_pug} from "./templates/warm_template_pug";
import {bye_template_pug} from "./templates/bye_template_pug";
import {followup_template_pug} from "./templates/followup_template_pug";

export const emailTemplates = {
    cold: cold_template_pug,
    warm: warm_template_pug,
    followup: followup_template_pug,
    bye: bye_template_pug,
}