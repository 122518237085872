import React, { useEffect, useState, useCallback } from 'react';
import './Header.css';
import { whoAmI } from '../../services/whoAmI';
import { getUserStats } from '../../services/userStats';
import { emailInSpamDb } from "../../services/emailInSpamDb";
import { lookupStatus } from "../../services/lookup";
import { setGlobalEmail } from "../../utils/globals";

const outreachesPerDayLimit = process.env.REACT_APP_OUTREACHES_PER_DAY_LIMIT;

const Header = () => {
  const [user, setUser] = useState(null);
  const [userStats, setUserStats] = useState({
    count: { ytd: 0, mtd: 0, wtd: 0, td: 0 },
    open_rate: { ytd: 0, mtd: 0, wtd: 0, td: 0 },
  });
  const [userEmailInSpamDb, setUserEmailInSpamDb] = useState(false);
  const [lookupApiStatus, setLookupApiStatus] = useState(null);

  // Memoized fetch functions to avoid redefinition on every render
  const fetchUserEmailInSpamDb = useCallback(async () => {
    if (user?.email) {
      const isEmailInSpamDb = await emailInSpamDb(user.email);
      setUserEmailInSpamDb(isEmailInSpamDb);
    }
  }, [user?.email]);

  const fetchUserDetails = useCallback(async () => {
    const statsData = await getUserStats();
    setUserStats(statsData);
  }, []);

  const fetchUser = useCallback(async () => {
    const userData = await whoAmI();
    setGlobalEmail(userData.email);
    setUser(userData);
  }, []);

  const fetchApiStatus = useCallback(async () => {
    const apiStatusData = await lookupStatus();
    setLookupApiStatus(apiStatusData);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]); // Only run on component mount, fetchUser is now memoized

  useEffect(() => {
    if (user) {
      fetchUserDetails();
      fetchApiStatus();
      fetchUserEmailInSpamDb();

      // Setup intervals after user is fetched
      const fetchUserDetailsInterval = setInterval(fetchUserDetails, 10 * 60 * 1000);
      const fetchApiStatusInterval = setInterval(fetchApiStatus, 5 * 60 * 1000);

      // Clear intervals on unmount
      return () => {
        clearInterval(fetchUserDetailsInterval);
        clearInterval(fetchApiStatusInterval);
      };
    }
  }, [user, fetchUserDetails, fetchApiStatus, fetchUserEmailInSpamDb]);

  if (!user?.name) {
    return null;
  }

  const formatLookupsAvailable = (left, total, period) => `${left}/${total} (${period})`;

  return (
      <div className='header-container'>
        <div className='banner-container'>
          {`Hello, ${user.name}`}
          <br />
          {`Email: ${user.email}`}
          <br />
          {userEmailInSpamDb && <span style={{ color: 'red' }}>Found in SPAM DB</span>}
        </div>

        <div className='total-emails-normal'>
          <span className='title'>📧 Year-to-date</span>
          <span className='value'>{userStats?.count?.ytd} (o/r {userStats?.open_rate?.ytd}%)</span>
        </div>
        <div className='total-emails-normal'>
          <span className='title'>📧 Month-to-date</span>
          <span className='value'>{userStats?.count?.mtd} (o/r {userStats?.open_rate?.mtd}%)</span>
        </div>
        <div className='total-emails-highlighted'>
          <span className='title'>📧 Week-to-date</span>
          <span className='value'>{userStats?.count?.wtd} (o/r {userStats?.open_rate?.wtd}%)</span>
        </div>
        <div className='total-emails-highlighted'>
          <span className='title'>📧 Today</span>
          <span className='value'>{userStats?.count?.td} of {outreachesPerDayLimit}</span>
        </div>

        {/* Daily Limits Section */}
        {lookupApiStatus?.apiLimits && (
            <div className='api-limits-container'>
              <h4>Lookups Usage</h4>
              <div>{formatLookupsAvailable(lookupApiStatus?.apiLimits?.requestLimits?.minuteRequestsLeft, lookupApiStatus?.apiLimits?.requestLimits?.rateLimitMinute, "minute")}</div>
              <div>{formatLookupsAvailable(lookupApiStatus?.apiLimits?.requestLimits?.hourlyRequestsLeft, lookupApiStatus?.apiLimits?.requestLimits?.rateLimitHourly, "hour")}</div>
            </div>
        )}
      </div>
  );
};

export default Header;
