import pMemoize from 'p-memoize';
import { getApiUrl } from '../utils/getApiUrl';
import { axiosRequestWithUserId } from "../utils/apiCalls";
import {isValidEmail} from './isValidEmail';

const getEmailStatus = pMemoize(
    async (email, template, company, outreaches, addedToStoplist) => {
        // Validate email and check if template is not empty
        if (!isValidEmail(email) || !template) {
            return undefined; // Return early if validation fails
        }

        const api = 'email_status';
        const url = `${getApiUrl()}/${api}?email=${email}&template=${template}&company=${company}`;
        const config = {
            method: 'get',
            url,
            maxBodyLength: Infinity,
        };

        try {
            const { data } = await axiosRequestWithUserId(config);
            return data;
        } catch (error) {
            console.error(error.toString());
        }
    },
    {
        cacheKey: (email, template, company, outreaches, addedToStoplist) =>
            `${email}:${template}:${company}:${outreaches}:${addedToStoplist}`,
        maxAge: 30 * 1000, // 30 sec
    }
);

const getEmailStatusUncached = async (email, template, company) => {
    // Validate email and check if template is not empty
    if (!isValidEmail(email) || !template) {
        return undefined; // Return early if validation fails
    }

    const api = 'email_status';
    const url = `${getApiUrl()}/${api}?email=${email}&template=${template}&company=${company}`;
    const config = {
        method: 'get',
        url,
        maxBodyLength: Infinity,
    };

    try {
        const { data } = await axiosRequestWithUserId(config);
        return data;
    } catch (error) {
        console.error(error.toString());
    }
};

export { getEmailStatus, getEmailStatusUncached };
