import {getApiUrl} from "../utils/getApiUrl";
import memoize from "lodash.memoize";
import {fetchWithUserId} from "../utils/apiCalls";

export const whoAmI = memoize(async () => {
    try {
        const result = await fetchWithUserId(`${getApiUrl()}/whoami`);

        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        return result.json();
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        console.error("An error occurred during the 'whoAmI' API call:", error);
        return {};
    }
})
