import {getUserName} from "./getUserName";
import axios from "axios";

export async function fetchWithUserId(url, options = {}) {
    const userId = await getUserName();
    const headers = {
        ...options.headers,
        'x-user-id': userId,
    };

    return fetch(url, {
        ...options,
        headers,
    });
}

export async function axiosGetWithUserId(url, options = {}) {
    const userId = await getUserName();
    const headers = {
        ...options.headers,
        'x-user-id': userId,
    };

    return axios.get(url, {
        ...options,
        headers,
    });
}

export async function axiosRequestWithUserId(config) {
    const userId = await getUserName();
    const headers = {
        ...config.headers,
        'x-user-id': userId,
    };

    return axios.request({
        ...config,
        headers,
    });
}

export async function axiosPostWithUserId(url, data, config = {}) {
    const userId = await getUserName();
    const headers = {
        ...config.headers,
        'x-user-id': userId,
    };

    return axios.post(url, data, {
        ...config,
        headers,
    });
}