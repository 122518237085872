import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_TOKEN,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  forwardReports: 'all',
  sampleRate: 100,
  telemetrySampleRate: 100,
  service: process.env.REACT_APP_NAME,
  env: process.env.REACT_APP_ENVIRONMENT,
  host: window.location.hostname,
  version: process.env.REACT_APP_VERSION,
})

export default datadogLogs.logger