import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import IconMail from '../../assets/mail-icon.png';
import IconMenu from '../../assets/menu-icon.png';
import IconMagnify from '../../assets/magnifier-icon.png';
import IconPieChart from '../../assets/pie-chart-icon.png';
import IconJobs from '../../assets/jobs-icon.png';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const paths = location.pathname.split('/');
  const selectedPath = paths[paths.length - 1];

  const sidebarElements = [
    { path: 'lookup', icon: IconMagnify },
    { path: 'jobs', icon: IconJobs },
    { path: 'template', icon: IconMail },
    { path: 'leads', icon: IconPieChart },
  ];

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        <img src={IconMenu} alt="toggle" />
      </button>
      <div className="sidebar-content">
        {sidebarElements.map((element) => (
          <Link
            key={element.path}
            className={`sidebar-element ${selectedPath === element.path ? 'active-page' : ''}`}
            to={`/${element.path}`}
          >
            <img src={element.icon} alt={element.path} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
