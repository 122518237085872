import { Editor } from '@monaco-editor/react'
import React from 'react'

const EditorSection = ({ setCode, editorValue, isEditAvailable }) => {
    return (
        <>
            <h3>Template</h3>
            <div className="editor-section">
                <Editor
                    placeholder="Please enter PUG code."
                    onChange={(evn, value) => {
                        setCode(evn)
                    }}
                    language="pug"
                    theme="light"
                    value={editorValue}
                    options={{
                        readOnly: !isEditAvailable,
                        wordWrap: "off",
                        inlineSuggest: true,
                        fontSize: "14px",
                        formatOnType: true,
                        autoClosingBrackets: true,
                        minimap: {
                            enabled: false
                        },
                        scrollbar: {
                            vertical: 'hidden'
                        }
                    }}
                />
            </div>
        </>
    )
}

export default EditorSection