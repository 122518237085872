import { getApiUrl } from "../utils/getApiUrl";
import logger from "../utils/logger";
import {axiosRequestWithUserId} from "../utils/apiCalls";

const batchSize = 1;

export const sendMassEmailsInBatches = async (
    subject, 
    baseTemplate, 
    customTemplateName, 
    leadList, 
    attachmentName, 
    attachment, 
    schedule = false, 
    code,
    campaignTitle,
    date,
    ) => 
{
    logger.log(`sendMassEmailsInBatches subject ${subject}, template ${baseTemplate}, customTemplate ${customTemplateName} leadList ${JSON.stringify(leadList)}}, attachment is ${attachment ? '' : 'not'} present (name=${attachmentName}), campaignTitle ${campaignTitle}, date ${date}`)
    
    const leadBatches = splitLeadListIntoBatches(leadList);
    const statusResults = {status: "OK", message: []};
    let batch = 0
    for (const leadBatch of leadBatches) {
        const result = await sendMassEmail(subject, baseTemplate, leadBatch, attachmentName, attachment, schedule, code, customTemplateName, campaignTitle, ++batch, date);
        if (schedule) {
            statusResults.message = statusResults.message.concat(
                new Array(batchSize).fill(result.message));    
        } else {
            statusResults.message = statusResults.message.concat(result.message);    
        }
        
        if (result.status !== "OK") {
            statusResults.status = result.status
            break
        }
    }

    return statusResults;
};

const splitLeadListIntoBatches = (leadList) => {
    const leadBatches = [];
    for (let i = 0; i < leadList.length; i += batchSize) {
        const batch = leadList.slice(i, i + batchSize);
        leadBatches.push(batch);
    }
    return leadBatches;
};

export const sendMassEmail = async (subject, template, leadList, attachmentName, attachment, schedule = false, code, customTemplateName, campaignTitle, batch, date) => {
    const api = schedule ? 'schedule_mass_emails' : 'send_mass_emails';
    const url = `${getApiUrl()}/${api}?`;
    const config = {
        method: 'post',
        url,
        maxBodyLength: Infinity,
    };
    
    try {
        const urlParams = new URLSearchParams();
        urlParams.append('code', code);
        urlParams.append('campaignTitle', campaignTitle);
        urlParams.append('batch', batch);
        
        if (attachmentName && attachment) {
            urlParams.append('attachmentName', attachmentName);
            config.data = attachment;
        }
        
        if (schedule) {
            urlParams.append('scheduledDate', date);    
        }
        
        urlParams.append('template', template);
        urlParams.append('customTemplate', customTemplateName);
        urlParams.append('subject', subject);
        urlParams.append('leadList', JSON.stringify(leadList));
        config.url = `${url}${urlParams.toString()}`;
        
        const { data } = await axiosRequestWithUserId(config);
        return data;
    } catch (error) {
        const data = error.response.data; 
        console.error(data.message);
        return {status: 'FAILED', message: data.message};
    }
};

export const getMassEmailSchedule = async () => {
    const url = `${getApiUrl()}/schedule_mass_emails`;
    const config = {
        method: 'get',
        url,
        maxBodyLength: Infinity,
    };

    try {
        const { data } = await axiosRequestWithUserId(config);
        return data;
    } catch (error) {
        console.error(error.toString());
        return { error: error.toString() };
    }
};

export const cancelMassEmailSchedule = async (email, campaignTitle, customTemplate, template) => {
    const url = `${getApiUrl()}/schedule_mass_emails`;

    const params = new URLSearchParams();
    params.append('email', email);
    params.append('campaignTitle', campaignTitle);
    params.append('customTemplate', customTemplate);
    params.append('template', template);

    const config = {
        method: 'delete',
        url: `${url}?${params.toString()}`,
        maxBodyLength: Infinity,
    };

    try {
        const {data} = await axiosRequestWithUserId(config);
        return data;
    } catch (error) {
        console.error(error.toString());
        throw error
    }
};