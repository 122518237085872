const cold_template_pug = `doctype html
html
  head
    block head
      meta(charset="utf-8")
      meta(http-equiv="X-UA-Compatible", content="IE=edge")
      meta(name="x-apple-disable-message-reformatting")
      style.
        a {
          font-size: 13px;
          color: #0000AA;
        }
        p, ul, li {
          margin: 0;
          font-family: "Apto Thin", sans-serif;
          font-size: 13px;
          color: #505050;
        }
        .paragraph-bottom-spacing {
          margin-bottom: 13px;
        }
        .italic {
          font-style: italic;
        }
        table {
          width: 100%;
          font-size: 11px;
          font-family: "Apto Thin", sans-serif;
        }
  body
    p(class="paragraph-bottom-spacing") Hi #{name},
    p(class="paragraph-bottom-spacing") Are you interested in reducing time searching for strong fulltime software developers? I'm #{managerName}, representing Altarey, a US-based company for IT recruitment. Over 10 years of experience in the industry at companies like Microsoft, Bloomberg, Twilio makes the foundation for our technical background. 
    p(class="paragraph-bottom-spacing") I'm eager to offer our expertise, automation and network at your disposal:
    ul
      li Fulltime developers on JavaScript, Python, Java, and C++ 
      li Candidates fully technically tested and soft-skills evaluated 
      li Hydrate recruitment funnel with high-fidelity candidates
    br  
    p(class="paragraph-bottom-spacing") Among our clients are HubSpot, Volvo, PWC, and Swiss Exchange. It would be our privilege to become your vendor.
    p(class="paragraph-bottom-spacing") Would you be interested to learn more about the benefits from this partnership?
    
    p(class="paragraph-bottom-spacing")
      each value, key in signature
        p=value
    p(class="paragraph-bottom-spacing italic") We respect your privacy. If not interested in this offer, you may add yourself to the stoplist 
      a(href=unsubscribeLink, target="_blank") here
      | .
    p(class="paragraph-bottom-spacing italic") This is a promotional email of a product or service offering by Altarey located at 1201 N Orange St #7643, Wilmington, DE 19801.
    img(src=trackingPixel alt="" width="1" height="1")
`
export {cold_template_pug};