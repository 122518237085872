import React, {useState} from 'react';
import Button from '../../components/Button/Button';
import './home.css';
import {FaCopy} from 'react-icons/fa';
import {
    lookupOrg,
    lookupOrgJobs,
    lookupPoc,
    lookupPocDetails
} from "../../services/lookup";
import {downloadJsonAsXl} from "../../utils/downloadXl";

const ROLES = {
    'CTO': 'CTO,Chief Technology Officer',
    'CIO': 'CIO,Chief Information Office',
    'CEO': 'CEO,Chief Executive Officer',
    'Founder': 'Founder,Co-founder,Cofounder,Owner,Founder & CEO',
    'Manager': 'Manager,Director,Manager & CEO',
    'BizDev': 'Business Development,Business Development Manager',
};

const LOOKUP_JOBS_ENABLED = process.env.REACT_APP_ENABLE_LOOKUP_JOBS

const Home = () => {
    const [companyName, setCompanyName] = useState('');
    const [personaTypeInLookupPoc, setPersonaTypeInLookupPoc] = useState(ROLES['CTO']);
    const [personaTypeInLookupCompanies, setPersonaTypeInLookupCompanies] = useState(ROLES['CTO']);
    const [industry, setIndustry] = useState('');
    const [location, setLocation] = useState('United States');
    const [headcount, setHeadcount] = useState('1,50');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const handleLookupCompanies = async () => {
        setLoading(true);

        try {
            console.log('Calling lookupOrg with params:', { industry, location, headcount });
            const { companySize, companies: orgs } = await lookupOrg(undefined, industry, location, headcount);

            console.log('lookupOrg returned:', { companySize, orgs });
    
            let leads = [];
            for (let i = 0; i < orgs.length; i++) {
                const org = orgs[i];
                console.log('Processing organization:', org);
    
                const lead = await lookupPoc([org.organization_id || org.id], personaTypeInLookupCompanies);
                console.log('lookupPoc returned lead:', lead);
    
                if (lead) {
                    leads.push(lead);
                }
            }

            const checkedLeads = [];
            for (const lead of leads) {
                if (!lead || lead.length === 0) continue; // Skip if lead is not valid
    
                const poc = lead;
                console.log('Processing Point of Contact (POC):', poc);
    
                const pocDetails = await lookupPocDetails(poc.person_id || poc.id);
                console.log('lookupPocDetails returned POC details:', pocDetails);
    
                let jobs = null;
                const orgId = pocDetails.organization_id || poc.organization_id;
                if (LOOKUP_JOBS_ENABLED && orgId) {
                    jobs = await lookupOrgJobs(orgId);
                }
    
                const checkedLead = {
                    companyName: poc.organization.name,
                    name: poc.name,
                    email: pocDetails.email || poc.email,
                    role: personaTypeInLookupCompanies.split(',')[0],
                    companySize: companySize,
                    jobs: jobs && jobs.length > 0 ? jobs.map(job => `${job.title}: ${job.url};`).join(' ') : '',
                    industry,
                    location
                };
    
                if (checkedLead.email) {
                    checkedLeads.push(checkedLead);
                }
            }
    
            let text = 'No leads found to download.'
            if (checkedLeads.length > 0) {
                await downloadJsonAsXl(checkedLeads);
                text = `Excel has been downloaded with ${checkedLeads.length} leads`;
            } 
            alert(text);
        } catch (error) {
            handleRequestError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleRequestError = (error) => {
        console.error('Error in processing input:', error);
        alert(`Error in processing input ${error}`);
    };

    const handleLookupPoc = async () => {
        setResult(null);
        setLoading(true);

        try {
            const orgIds = await lookupOrg(companyName, undefined, undefined);
            if (!orgIds ||  orgIds.length === 0) {
                const error = `No org found`;
                throw new Error(error);
            }
            
            const orgId = orgIds.find(id => id !== undefined && id !== null);
            let jobs = null;
            if (orgId !== undefined) {
                jobs = await lookupOrgJobs(orgId);
            }

            const response = await lookupPoc(orgIds, personaTypeInLookupPoc);
            if (!response || response.length === 0) {
                const error = `No ${personaTypeInLookupPoc} at ${companyName}`;
                throw new Error(error);
            }

            const poc = response;
            const pocDetail = await lookupPocDetails(poc.person_id || poc.id);

            const resultData = {
                name: pocDetail.name || poc.name,
                email: pocDetail.email || poc.email,
                role: pocDetail.title || poc.title,
                company: companyName,
                companyId: pocDetail.organization_id || poc.organization_id,
                jobs
            };

            setResult(resultData);
        } catch (error) {
            handleRequestError(error)
        } finally {
            setLoading(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="two-column-layout">
            {/* Part 1: Company Name, Persona Type, and Find Button */}
            <div className="column">
                <h3 className="column-heading">Find a single lead</h3>
                <div className="input-group">
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="location">Location:</label>
                    <select
                        id="location"
                        value="World"
                        onChange={(e) => setLocation(e.target.value)}
                        disabled // Grayed out
                    >
                        <option value="United States">United States</option>
                        <option value="World">World</option>
                        <option value="Canada">Canada</option>
                        <option value="United Arab Emirates">Emirates</option>
                        {/* Add other location options here */}
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="headcount">Headcount:</label>
                    <select
                        id="headcount"
                        /*value={location}*/
                        /*onChange={(e) => setLocation(e.target.value)}*/
                        disabled // Grayed out
                    >
                        <option value="Any">Any</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="personaTypeInFindPoc">Persona Type:</label>
                    <select
                        id="personaTypeInFindPoc"
                        value={personaTypeInLookupPoc}
                        onChange={(e) => setPersonaTypeInLookupPoc(e.target.value)}
                    >
                        <option value={ROLES['CTO']}>CTO</option>
                        <option value={ROLES['Founder']}>Founder</option>
                        <option value={ROLES['BizDev']}>BizDev</option>
                        <option value={ROLES['Manager']}>Manager/Director</option>
                        <option value={ROLES['CEO']}>CEO</option>
                        <option value={ROLES['CIO']}>CIO</option>
                    </select>
                </div>
                <Button onClick={handleLookupPoc} style={{marginLeft: '0px'}}>
                    Submit
                </Button>

                {result && (
                    <div className="result-container" style={{marginLeft: '0px', marginTop: '20px'}}>
                        <p>
                            Name: {result.name}{' '}
                            <FaCopy onClick={() => handleCopyToClipboard(result.name)} style={{cursor: 'pointer'}}/>
                        </p>
                        <p>
                            Email: {result.email}{' '}
                            <FaCopy onClick={() => handleCopyToClipboard(result.email)} style={{cursor: 'pointer'}}/>
                        </p>
                        <p>
                            Role: {result.role}{' '}
                            <FaCopy onClick={() => handleCopyToClipboard(result.role)} style={{cursor: 'pointer'}}/>
                        </p>
                        <p>
                            Company: {result.company}{' '}
                            <FaCopy onClick={() => handleCopyToClipboard(result.company)} style={{cursor: 'pointer'}}/>
                        </p>
                        <p>
                            CompanyId: {result.companyId}{' '}
                            <FaCopy onClick={() => handleCopyToClipboard(result.companyId)}
                                    style={{cursor: 'pointer'}}/>
                        </p>
                        <div className="job-links">
                            {result && result.jobs && (
                                <div>
                                    <p>Open {result.jobs.length} jobs</p>
                                    {result.jobs.map((job, index) => (
                                        <div key={index} className="job-link-container" style={{marginLeft: '0px'}}>
                                            {job.url && (
                                                <a href={job.url} target="_blank" rel="noopener noreferrer">
                                                    {job.title}
                                                </a>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Divider */}
            <div className="divider"></div>

            {/* Part 2: Industry, Location, Persona Type, and Find Button */}
            <div className="column">
                <h3 className="column-heading">Find multiple leads</h3>
                <div className="input-group">
                    <label htmlFor="industry">Industry:</label>
                    <input
                        type="text"
                        id="industry"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="location">Location:</label>
                    <input
                        list="location-options"
                        id="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                    <datalist id="location-options">
                        <option value="United States"/>
                        <option value="Canada"/>
                        <option value="United Arab Emirates"/>
                        <option value="Hong Kong"/>
                        <option value="Singapore"/>
                        <option value="Germany"/>
                        <option value="Switzerland"/>
                        <option value="Latvia"/>
                        {/* Add other location options here */}
                    </datalist>
                </div>
                <div className="input-group">
                    <label htmlFor="headcount">Headcount:</label>
                    <select
                        id="headcount"
                        value={headcount}
                        onChange={(e) => setHeadcount(e.target.value)}
                    >
                        <option value="1,50">1-50</option>
                        <option value="51,100">51-100</option>
                        <option value="101,500">101-500</option>
                        <option value="501,1000">501-1000</option>
                        <option value="1001,5000">1001-5000</option>
                        <option value="5001,10000">5001-10000</option>
                    </select>
                </div>
                {/* New Persona Type Dropdown */}
                <div className="input-group" style={{marginLeft: '0px'}}>
                    <label htmlFor="personaTypeInFindCompanies">Persona Type:</label>
                    <select
                        id="personaTypeInFindCompanies"
                        value={personaTypeInLookupCompanies}
                        onChange={(e) => setPersonaTypeInLookupCompanies(e.target.value)}
                    >
                        <option value={ROLES['CTO']}>CTO</option>
                        <option value={ROLES['BizDev']}>BizDev</option>
                        <option value={ROLES['Manager']}>Manager/Director</option>
                        <option value={ROLES['CEO']}>CEO</option>
                        <option value={ROLES['Founder']}>Founder</option>
                        <option value={ROLES['CIO']}>CIO</option>
                    </select>
                </div>
                <Button onClick={handleLookupCompanies} style={{marginLeft: '0px'}}>
                    Submit
                </Button>
            </div>

            {/* Loading, Error, and Result Sections */}
            {loading && (
                <div className="loading-spinner-overlay fullscreen">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
};

export default Home;
