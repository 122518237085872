import {getApiUrl} from "../utils/getApiUrl";
import {axiosRequestWithUserId} from "../utils/apiCalls";

export const getXlsxAsJson = async (attachment) => {
    const api = 'xl_to_json'
    const url = `${getApiUrl()}/${api}`
    const config = {
        method: 'post',
        url,
        maxBodyLength: Infinity,
        data: attachment
    }
    
    try {
        const { data } = await axiosRequestWithUserId(config)
        return data
    } catch (error) {
        console.error(error.toString())
    }
}

export const getJsonAsXlsx = async (attachment) => {
  const api = 'json_to_xl';
  const url = `${getApiUrl()}/${api}`;
  
  const headers = {'Content-Type': 'blob'};
  const config = {url, method: 'post', data: attachment, responseType: 'arraybuffer', headers};
    
  try {
    const {data} = await axiosRequestWithUserId(config)
    return data;
  } catch (error) {
    console.error(error.toString());
    throw new Error('Failed to get Excel file from server.');
  }
};