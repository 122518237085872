import React from 'react';
import { useRoutes } from 'react-router-dom';
import LeadsPage from './pages/Leads';
import EmailTemplate from "./pages/EmailTemplate/EmailTemplate";
import Lookup from "./pages/Lookup"
import Jobs from "./pages/Jobs"

const Routes = () => {
  return useRoutes([
    {
      path: '/leads',
      element: <LeadsPage />,
    },
    {
      path: '/template',
      element: <EmailTemplate />,
    },
    {
      path: '/lookup',
      element: <Lookup />,
    },
    {
      path: '/jobs',
      element: <Jobs />,
    },
  ]);
};

export default Routes;
