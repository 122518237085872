// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    width: 120px;
    height: 32px;
    padding: 0px 10px 0px 10px;
    background: #EBEBEB;
    color: #232323;
    border-color: #232323;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px 3px 3px 3px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-container:hover {
    background-color: #232323;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;IACnB,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".button-container {\n    width: 120px;\n    height: 32px;\n    padding: 0px 10px 0px 10px;\n    background: #EBEBEB;\n    color: #232323;\n    border-color: #232323;\n    border-width: 1px;\n    border-style: solid;\n    border-radius: 3px 3px 3px 3px;\n    font-weight: 400;\n    font-size: 14px;\n    text-align: center;\n    cursor: pointer;\n    transition: all 0.3s ease;\n}\n\n.button-container:hover {\n    background-color: #232323;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
