import pMemoize from 'p-memoize';
import { getApiUrl } from '../utils/getApiUrl';
import {axiosRequestWithUserId} from "../utils/apiCalls";

const getEmailDate = pMemoize(
  async (email, template, status) => {
    const api = 'email_date';
    const url = `${getApiUrl()}/${api}?email=${email}&template=${template}`;
    const config = {
      method: 'get',
      url,
      maxBodyLength: Infinity,
    };

    try {
      const { data } = await axiosRequestWithUserId(config);
      return data.date ? new Date(data.date) : undefined;
    } catch (error) {
      console.error(error.toString());
    }
  },
  {
    cacheKey: (email, template, status) => `${email}:${template}:{status}`,
    maxAge: 5 * 60 * 1000, // 5 min
  }
);

export { getEmailDate };
