import React from 'react'
import Modal from 'react-modal'
import Button from '../../../components/Button/Button'

const NewTemplateModal = ({ 
    isOpen, 
    onRequestClose,
    createNewTemplate
}
) => {
    return (
        <Modal isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Confirm Action"
            style={{
                overlay: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    position: 'relative',
                    top: 'auto',
                    left: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                    border: '1px solid #ccc',
                    background: '#fff',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '50px',
                },
            }}>
            <h2>Select a template</h2>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px'
            }}>
                <Button style={{
                    padding: '6px 12px',
                    width: '100%'
                }} onClick={() => createNewTemplate('cold')}>Cold</Button>
                <Button style={{
                    padding: '6px 12px',
                    width: '100%'
                }} onClick={() => createNewTemplate('warm')}>Warm</Button>
                <Button style={{
                    padding: '6px 12px',
                    width: '100%'
                }} onClick={() => createNewTemplate('followup')}>FollowUp</Button>
                <Button style={{
                    padding: '6px 12px',
                    width: '100%'
                }} onClick={() => createNewTemplate('bye')}>Bye</Button>
            </div>
        </Modal>
    )
}

export default NewTemplateModal