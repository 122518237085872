import {getJsonAsXlsx} from "../services/parseXl";

export const downloadJsonAsXl = async (json) => {
    if (json && json.length > 0 && json[0]) {
        const jsonToXlResponse = await getJsonAsXlsx(json);
        const url = URL.createObjectURL(new Blob([jsonToXlResponse]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'output.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    } else {
        throw new Error('No data to process.');
    }
}