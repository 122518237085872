import Modal from "react-modal";
import Button from "../../components/Button/Button";
import React from "react";

const ConfirmModal = ({isOpen, onClose, onConfirm}) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Confirm Action"
        style={{
            overlay: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
                position: 'relative',
                top: 'auto',
                left: 'auto',
                right: 'auto',
                bottom: 'auto',
                border: '1px solid #ccc',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '4px',
                outline: 'none',
                padding: '20px',
                // minWidth: '300px', // adjust as needed
                // maxWidth: '600px', // adjust as needed
            },
        }}
    >
        <h2>Confirm Action</h2>
        <p>Are you sure you want to proceed?</p>
        <Button onClick={onConfirm}>Proceed</Button>
        <Button onClick={onClose}>Cancel</Button>
    </Modal>
);

export default ConfirmModal