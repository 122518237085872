import {getApiUrl} from "../utils/getApiUrl";
import memoize from "lodash.memoize";
import {fetchWithUserId} from "../utils/apiCalls";

export const emailInSpamDb = memoize(async (email) => {
    try {
        const result = await fetchWithUserId(`${getApiUrl()}/check_email?email=${email}`);

        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        const reply = await result.json()
        return reply.status !== 'OK';
    } catch (error) {
        console.error("An error occurred during the 'check_email' API call:", error);
        return {};
    }
})
