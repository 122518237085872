import {getApiUrl} from "../utils/getApiUrl";
import {fetchWithUserId} from "../utils/apiCalls";

export const getUserStats = async () => {
    try {
        const result = await fetchWithUserId(`${getApiUrl()}/user_stats`);

        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        return result.json();
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        console.error("An error occurred during the 'user_stats' API call:", error);
        return {};
    }
}

export const getUserStatsToday = async () => {
    try {
        const result = await fetchWithUserId(`${getApiUrl()}/user_stats/today`);

        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }

        return result.json();
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        console.error("An error occurred during the 'user_stats/today' API call:", error);
        return {};
    }
}