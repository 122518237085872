export const addBusinessDays = (startDate, numBusinessDays) => {
    const weekdays = [1, 2, 3, 4, 5]; // Monday to Friday

    let current = new Date(startDate);
    let businessDaysRemaining = numBusinessDays;

    while (businessDaysRemaining > 0) {
        current.setDate(current.getDate() + 1); // Move to the next day

        const dayOfWeek = current.getDay();

        if (weekdays.includes(dayOfWeek)) {
            businessDaysRemaining--;
        }
    }

    return current;
};