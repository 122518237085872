import React, { createContext, useEffect, useState } from 'react';
import { emailTemplates } from '../pages/EmailTemplate/EmailTemplates';

export const EmailContext = createContext();

export const EmailContextProvider = ({ children }) => {
    const [emails, setEmails] = useState([]);
    const [emailTemplate, setEmailTemplate] = useState("cold")
    const [defaultTemplates] = useState([
        {
            id: 1,
            title: 'Cold',
            template: 'cold',
            code: emailTemplates['cold']

        },
        {
            id: 2,
            title: 'Warm',
            template: 'warm',
            code: emailTemplates['warm']
        },
        {
            id: 3,
            title: 'FollowUp',
            template: 'followup',
            code: emailTemplates['followup']
        },
        {
            id: 4,
            title: 'Bye',
            template: 'bye',
            code: emailTemplates['bye']
        },
    ])

    useEffect(() => {
    }, [])

    const providerValues = {
        emails,
        setEmails,
        emailTemplate,
        setEmailTemplate,
        defaultTemplates,
    }

    return (
        <EmailContext.Provider value={providerValues}>
            {children}
        </EmailContext.Provider>
    );
};

export default EmailContextProvider;

export const useEmailContext = () => {
    const context = React.useContext(EmailContext);
    if (context === undefined) {
        throw new Error('useEmailContext must be used within a EmailContextProvider');
    }
    return context;
}
