import React from 'react';
import PugToHtmlConverter from './PugConverter';

const EmailTemplate = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <PugToHtmlConverter />
    </div>
  );
};

export default EmailTemplate;
