import React, { useState } from "react";
import Modal from "react-modal";

const PasswordModal = ({ isOpen, onPasswordSubmit }) => {
  const [selectedUsername, setSelectedUsername] = useState('');
  const [password, setPassword] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setSelectedUsername(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (selectedUsername && password) {
      onPasswordSubmit(selectedUsername, password);
    } else {
      alert('Please select a username and enter a password.');
    }
  };

  return (
      <Modal
          isOpen={isOpen}
          contentLabel="Password Modal"
          style={{
            overlay: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backdropFilter: isOpen ? 'blur(5px)' : 'none',  // Apply blur when the modal is open
              WebkitBackdropFilter: isOpen ? 'blur(5px)' : 'none',  // For Safari
            },
            content: {
              position: 'relative',
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
              border: '1px solid #ccc',
              background: 'rgba(255, 255, 255, 0.9)',  // Transparent background for the modal content
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
            },
          }}
      >
        <h2>User login</h2>
        <div>
          <label htmlFor="username">Username:</label>
          <select
              id="username"
              value={selectedUsername}
              onChange={handleUsernameChange}
          >
            <option value=""></option>
            <option value="alexey">Alexey</option>
            <option value="gleb">Gleb</option>
            <option value="lindo">Lindo</option>
            {/*<option value="alexey-as">Alexey-AS</option>*/}
            <option value="max">Max</option>
            {/*<option value="max-as">Max-AS</option>*/}
          </select>
        </div>
        <div>
        <label htmlFor="password">Password:</label>
          <input
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyDown}
          />
        </div>
        <button onClick={handleSubmit}>Submit</button>
      </Modal>
  );
};

export default PasswordModal;
