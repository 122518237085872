import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import { cancelMassEmailSchedule, getMassEmailSchedule, sendMassEmailsInBatches } from '../../services/sendMassEmail';
import 'react-calendar/dist/Calendar.css';
import './home.css';
import IconAttachment from '../../assets/attachment-icon.png';
import ScheduleImage from '../../assets/schedule-nyc.png';
import HolidaysImage from '../../assets/holidays.png';
import { TableRow } from "./TableRow/TableRow";
import { getXlsxAsJson } from "../../services/parseXl";
import { getEmailStatus } from "../../services/emailStatus";
import cloneDeep from 'lodash/cloneDeep';
import logger from "../../utils/logger";
import { verifyLeads } from "./verifyLeads";
import { doRenderScheduledTooltip } from "./tooltips/renderScheduledTooltip";
import { defaultCampaignFields } from "./defaultCampaignFields";
import { useEmailContext } from "../../context/emailContext";
import { getUserStatsToday } from "../../services/userStats";
import DateCalendarModal from './Calendar/DateСalendarModal';
import CalendarOutreaches from './Calendar/CalendarOutreaches';
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

const Home = () => {
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [sendEmailCallback, setSendEmailCallback] = useState();
    const [scheduledOutreaches, setScheduledOutreaches] = useState([]);
    const [outreachCount, setOutreachCount] = useState(0);
    const [isCalendarVisible, setCalendarVisible] = useState(false);
    const [tooltipScheduledContent, setTooltipScheduledContent] = useState(null);
    const [tooltipScheduledPosition, setTooltipScheduledPosition] = useState({ top: '200px', left: '500px' });
    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [campaigns, setCampaigns] = useState([cloneDeep(defaultCampaignFields)]);
    const [templates, setTemplates] = useState([]);
    const [currentCampaignIndex, setCurrentCampaignIndex] = useState(0);
    const [tooltipResponseRate, setTooltipResponseRate] = useState(null);
    const [tooltipResponseRatePosition, setTooltipResponseRatePosition] = useState({ top: '200px', left: '500px' });
    const [tooltipResponseRateVisible, setTooltipResponseRateVisible] = useState(false);
    const [isDateSelectionModalOpen, setDateSelectionPromptOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query for campaigns

    const handleRefresh = () => {
        setOutreachCount(outreachCount + 1)
        fetchScheduledOutreaches()
    };

    const {
        defaultTemplates,
    } = useEmailContext()

    const handleToggleResponseRateTooltip = () => {
        setTooltipResponseRateVisible((prevVisible) => !prevVisible);
    };

    function getSelectedTemplateBaseName() {
        const template = findTemplate(templates, getSelectedTemplateCustomName())
        return template && template.value ? template.value : 'cold'
    }

    async function calculateOpenedRate() {
        const leads = campaigns[currentCampaignIndex].leads;
        const len = leads && leads.length;
        let totalOpened = 0;

        if (len) {
            await Promise.all(
                leads.map(async (lead) => {
                    const status = await getEmailStatus(lead.email, getSelectedTemplateBaseName(), lead.company, outreachCount);
                    if (!status) {
                        return
                    }
                    const { opened, replied, inStoplist } = status;
                    if ((opened && opened.status) ||
                        (inStoplist && inStoplist.status) ||
                        (replied && replied.status.regular)) {
                        totalOpened++;
                    }
                })
            );
        }

        return len ? (totalOpened / len) * 100 : 0;
    }

    async function calculateRepliedRate() {
        const leads = campaigns[currentCampaignIndex].leads;
        const len = leads && leads.length;
        let totalReplied = 0;

        if (len) {
            await Promise.all(
                leads.map(async (lead) => {
                    const status = await getEmailStatus(lead.email, getSelectedTemplateBaseName(), lead.company, outreachCount);
                    if (!status) {
                        return
                    }
                    const { replied } = status;
                    if (replied && replied.status.regular) {
                        totalReplied++;
                    }
                })
            );
        }

        return len ? (totalReplied / len) * 100 : 0;
    }

    async function calculateRates() {
        try {
            const openedRate = await calculateOpenedRate();
            const repliedRate = await calculateRepliedRate();

            const content = (
                <div>
                    <h5>Open rate {openedRate.toFixed(2)}%</h5>
                    <h5>Reply rate {repliedRate.toFixed(2)}%</h5>
                </div>
            );

            setTooltipResponseRate(content);

            // Get the position of the triggering element (adjust as needed)
            const triggeringElement = document.getElementById('my-anchor-element');
            const rect = triggeringElement && triggeringElement.getBoundingClientRect();
            if (rect) {
                // Update tooltip position relative to the triggering element
                setTooltipResponseRatePosition({
                    top: `${rect.bottom}px`,
                    left: `${rect.left}px`,
                });
            }
        } catch (error) {
            console.error('Error calculating rates:', error);
        }
    }

    const handleClearLeads = () => {
        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs];
            newTabs[currentCampaignIndex].leads = [];
            return newTabs;
        });
    };

    // Function to add a new tab
    const handleAddTab = () => {
        const newTab = cloneDeep(defaultCampaignFields)
        newTab.title = `Campaign ${campaigns.length + 1}`
        const newTabs = [...campaigns, newTab];
        setCampaigns(newTabs);
        setCurrentCampaignIndex(newTabs.length - 1);
    };

    const handleCloneTab = () => {
        const currentTab = campaigns[currentCampaignIndex];
        const clonedTab = cloneDeep(currentTab);
        clonedTab.title = `Clone of ${currentTab.title}`;

        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs, clonedTab]; // Push the cloned tab directly
            return newTabs;
        });

        // Switch to the newly cloned tab
        handleChangeTab(campaigns.length)
    };

    // Function to update the title of a tab
    const handleUpdateTabTitle = (index, newTitle) => {
        const newTabs = [...campaigns];
        newTabs[index].title = newTitle;
        setCampaigns(newTabs);
    };

    const handleChangeTab = (index) => {
        setCurrentCampaignIndex(index);
    };

    const handleImportLeadsFilePicker = () => {
        const uploadOutputFileInput = document.getElementById('upload-output-file-input');
        if (uploadOutputFileInput) {
            uploadOutputFileInput.click();
        } else {
            console.error('uploadOutputFileInput not found');
        }
    };

    const handleImportLeadsFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            try {
                const result = await getXlsxAsJson(file);
                if (result) {
                    result.forEach((lead) => {
                        handleAddRow(lead);
                    });
                }
            } catch (error) {
                console.error('Error while processing the file:', error);
                alert(`Error while processing the file: ${error}`);
            }
        } else {
            alert('No file selected');
        }
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        const content = renderScheduledTooltip(date);
        if (content) {
            setTooltipScheduledContent(content);
        } else {
            setTooltipScheduledContent(null);
        }
    };

    const handleMouseLeave = () => {
        setTooltipScheduledContent(null);
    };

    const toggleCalendarVisibility = () => {
        setCalendarVisible((prevVisible) => !prevVisible);
    };

    const fetchScheduledOutreaches = async () => {
        if (process.env.REACT_APP_API_FETCH_TASKS) {
            const schedule = await getMassEmailSchedule();
            if (scheduledOutreaches !== schedule.tasks) {
                setScheduledOutreaches(schedule.tasks);
            }
        }
    };

    const renderScheduledTooltip = doRenderScheduledTooltip(scheduledOutreaches);

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            loadWorkspace();
            fetchScheduledOutreaches();
        };

        // Initial fetch
        fetchData();

        // Set interval to fetch data every 30 seconds
        const intervalId = setInterval(fetchScheduledOutreaches, 30000);

        // Clean up function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        calculateRates();
    }, [campaigns, currentCampaignIndex, isLoading, outreachCount]);

    useEffect(() => {
        fetchScheduledOutreaches();
    }, [campaigns, currentCampaignIndex, isLoading]);

    function saveWorkspace() {
        localStorage.setItem('tabs', JSON.stringify(campaigns));
        localStorage.setItem('currentTabIndex', currentCampaignIndex.toString());
    }

    function loadWorkspace() {
        let savedCampaigns = localStorage.getItem('tabs');
        const savedCurrentCampaignIndex = localStorage.getItem('currentTabIndex');

        if (savedCampaigns && savedCurrentCampaignIndex) {
            savedCampaigns = JSON.parse(savedCampaigns);
            setCurrentCampaignIndex(parseInt(savedCurrentCampaignIndex, 10));
        }

        let savedTemplates = JSON.parse(localStorage.getItem('templateTabs')) || [];
        savedTemplates = savedTemplates.concat(defaultTemplates)
        const readTemplates = savedTemplates.map((template) => ({
            value: template.template,
            label: template.title,
            html: template.html,
            code: template.code,
            attachment: null,
            attachmentName: '',
            isAttachmentPresent: false,
        }));
        setTemplates(readTemplates)

        if (savedCampaigns) {
            savedCampaigns.forEach(campaign => {
                const hasSelectedTemplate = savedTemplates.some(template => template.title === campaign.selectedTemplateTitle);
                if (!hasSelectedTemplate) {
                    campaign.selectedTemplateTitle = 'Cold';
                }
            });

            // Assuming there's a function to update campaigns after modification
            setCampaigns(savedCampaigns);
        }
    }

    function assignAttachment(value) {
        logger.log(`set attachment binary for template ${getSelectedTemplateCustomName()}`)
        setTemplates((prevTemplates) => {
            const newTemplates = [...prevTemplates];
            const template = findTemplate(newTemplates, getSelectedTemplateCustomName())
            template.attachment = value;
            return newTemplates;
        });
    }

    const handleAttachmentFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            assignAttachment(file);
            assignAttachmentName(file.name);
            assignAttachmentPresent(!!file);

            // IMPORTANT
            // Reset the file input after file selection
            // IMPORTANT
            event.target.value = null;
        } else {
            alert('No attachment selected');
        }
    };

    function assignAttachmentPresent(value) {
        logger.log(`set attachment present as ${value} for template ${getSelectedTemplateCustomName()}`)
        setTemplates((prevTemplates) => {
            const newTemplates = [...prevTemplates];
            const template = findTemplate(newTemplates, getSelectedTemplateCustomName())
            template.isAttachmentPresent = value;
            return newTemplates;
        });
    }

    const handleAttachmentRemoval = () => {
        assignAttachment(null);
        assignAttachmentName('');
        assignAttachmentPresent(false);
    };

    function assignAttachmentName(value) {
        logger.log(`set attachment name ${value} for template ${getSelectedTemplateCustomName()}`)
        setTemplates((prevTemplates) => {
            const newTemplates = [...prevTemplates];
            const template = findTemplate(newTemplates, getSelectedTemplateCustomName())
            template.attachmentName = value;
            return newTemplates;
        });
    }

    const handleAttachmentNameChange = (event) => {
        assignAttachmentName(event.target.value);
    };

    const findTemplate = (templates, name) => {
        return templates.find(template => template.label.toLowerCase() === name.toLowerCase())
    }

    const handleChangeTemplate = (event) => {
        const newTemplate = event.target.value

        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs];
            newTabs[currentCampaignIndex].selectedTemplateTitle = newTemplate;
            return newTabs;
        });
        logger.log(`changed templated from ${getSelectedTemplateCustomName()} to ${newTemplate}`)
        clearStatusColumn();
    };

    function assignSubject(value) {
        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs];
            newTabs[currentCampaignIndex].subject = value;
            return newTabs;
        });
    }

    const handleChangeSubject = (event) => {
        assignSubject(event.target.value);
    };

    const handleChangeLeads = (event, index, field) => {
        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs];
            const newLeads = [...newTabs[currentCampaignIndex].leads];
            newLeads[index] = {
                ...newLeads[index],
                [field]: event.target.value,
            };
            newTabs[currentCampaignIndex].leads = newLeads;
            return newTabs;
        });
    };

    // Remove the lead for the current tab
    const handleDeleteRow = (index) => {
        const updatedTabs = campaigns.map((tab, tabIndex) => {
            if (tabIndex === currentCampaignIndex) {
                return { ...tab, leads: tab.leads.filter((lead, leadIndex) => leadIndex !== index) };
            }
            return tab;
        });

        setCampaigns(updatedTabs);
    };

    const handleAddRowEmpty = () => {
        handleAddRow()
    };

    // Function to check if lead.jobs contain relevant keywords and return modified roles
    const filterRelevantJobsByKeywords = (jobs) => {
        const relevantKeywords = ['software engineer', 'software developer', 'devops', 'designer', 'qa', 'quality assurance'];

        if (!jobs || typeof jobs !== 'string') {
            return null; // Return null if jobs is not provided or not a string
        }

        // Split jobs string into an array of roles
        const jobsWithTitle = jobs.split("; ").map(role => role.split(": "));

        // Filter roles that contain relevant keywords
        const filteredRoles = jobsWithTitle.filter(role => {
            const roleLower = role[0].toLowerCase();
            return relevantKeywords.some(keyword => roleLower.includes(keyword));
        });

        const concatenatedString = filteredRoles.map(item => item.join(': ').concat(';')).join('');
        return concatenatedString;
    };

    const handleAddRow = (newLead = {
        name: '',
        company: '',
        email: '',
        status: '',
        role: '',
        jobs: ''
    }) => {
        newLead.jobs = filterRelevantJobsByKeywords(newLead.jobs)

        // Update leads for the current tab
        setCampaigns((prevTabs) => {
            return prevTabs.map((tab, index) => {
                if (index === currentCampaignIndex) {
                    return {
                        ...tab,
                        leads: [...tab.leads, { ...newLead, id: tab.leads.length + 1 }],
                    };
                }
                return tab;
            });
        });

        // Scroll a bit vertically down
        window.scrollTo({
            top: window.scrollY + 100, // You can adjust the value as needed
            behavior: 'smooth', // You can change this to 'auto' for an instant scroll
        });
    };

    const areLeadsValid = verifyLeads(campaigns, currentCampaignIndex, assignAttachmentName, templates, findTemplate);

    const doSendMassEmails = async () => {
        const { leads, subject, title: campaignTitle } = campaigns[currentCampaignIndex];
        const { attachment, attachmentName, code, value } = getSelectedTemplate();
        const customValue = await getSelectedTemplateCustomName()
        const result = await sendMassEmailsInBatches(
            subject,
            value,
            customValue,
            leads,
            attachmentName,
            attachment,
            false,
            code,
            campaignTitle,
        );
        return result;
    };

    const doScheduleMassEmails = async (date) => {
        const { leads, subject, title: campaignTitle } = campaigns[currentCampaignIndex];
        const { attachment, attachmentName, code, value } = getSelectedTemplate();
        const customValue = await getSelectedTemplateCustomName()
        const result = await sendMassEmailsInBatches(
            subject,
            value,
            customValue,
            leads,
            attachmentName,
            attachment,
            true,
            code,
            campaignTitle,
            date
        );

        if (result.status !== 'OK') {
            alert(result.message[0])
            return { status: result.status, message: result.message };
        }

        const scheduledTimes = result.message.map(msg => {
            const match = msg.match(/scheduled for (.+)$/);
            const extractedPart = match ? match[1] : null;
            const dateObject = new Date(extractedPart);

            // Format options for a pretty date
            const dateFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
            const timeFormatOptions = { hour: 'numeric', minute: 'numeric' };

            // Convert to local time and format in a pretty way
            const localDateString = dateObject.toLocaleDateString(undefined, dateFormatOptions);
            const localTimeString = dateObject.toLocaleTimeString(undefined, timeFormatOptions);

            return `${localDateString} ${localTimeString}`;
        });

        return { status: result.status, message: scheduledTimes };
    };

    function handleScheduledEmailsWithDialog(emailCallback, date) {
        if (date) {
            setSendEmailCallback(() => emailCallback.bind(this, date));
        } else {
            setSendEmailCallback(() => emailCallback.bind(this));
        }
        setConfirmModalOpen(true);

        const timeoutId = setTimeout(() => {
            fetchScheduledOutreaches();
        }, 5 * 1000); // 5 sec in msec

        return () => {
            clearTimeout(timeoutId);
        }
    }

    const handleOutreach = async (emailCallback, date) => {
        const areValid = areLeadsValid();
        if (areValid.status !== 'OK') {
            alert(areValid.message);
            return;
        }

        const userStats = await getUserStatsToday()
        const dailyLimit = process.env.REACT_APP_OUTREACHES_PER_DAY_LIMIT
        if (userStats && userStats.count && userStats.count.td > dailyLimit) {
            alert(`This outreach will exceed the mailbox limit (${dailyLimit}`);
            return;
        }

        return handleScheduledEmailsWithDialog.call(this, emailCallback, date);
    };

    const handleOutreachNow = async () => {
        return handleOutreach(doSendMassEmails);
    };

    const handleOutreachDuringBizHours = async (date) => {
        return handleOutreach(doScheduleMassEmails, date);
    };

    const doCancelMassEmailSchedule = async () => {
        const { leads, title: campaignTitle } = campaigns[currentCampaignIndex];
        const { value } = getSelectedTemplate();
        const customValue = await getSelectedTemplateCustomName();

        const result = await Promise.all(leads.map(async (lead) => {
            try {
                const result = await cancelMassEmailSchedule(lead.email, campaignTitle, customValue, value);
                return { status: result.status, message: result.message };
            } catch (error) {
                return { status: "FAILED", message: 'Cannot cancel. Already cancelled?' };
            }
        }));

        return result;
    };

    const handleCancelOutreachDuringBizHours = async () => {
        return handleScheduledEmailsWithDialog.call(this, doCancelMassEmailSchedule);
    };

    const handleConfirm = async () => {
        setConfirmModalOpen(false);
        setIsLoading(true);

        try {
            clearStatusColumn();
            let leads = campaigns[currentCampaignIndex].leads
            const result = await sendEmailCallback();
            leads = leads.map((lead, index) => ({
                ...lead,
                status: Array.isArray(result) ? result[index].message : result?.message[index],
            }));

            // Update status in state
            setCampaigns((prevTabs) => {
                const newTabs = [...prevTabs];
                newTabs[currentCampaignIndex].leads = leads;
                return newTabs;
            });

            saveWorkspace();
        } finally {
            setIsLoading(false);
            setOutreachCount(outreachCount + 1)
        }
    };

    function clearStatusColumn() {
        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs];
            newTabs[currentCampaignIndex].leads = newTabs[currentCampaignIndex].leads.map((lead) => ({
                ...lead,
                status: '', // Clear the status after sending emails
            }));
            return newTabs;
        });
    }

    function getSelectedTemplateCustomName() {
        return campaigns.length > 0 ? campaigns[currentCampaignIndex].selectedTemplateTitle : null;
    }

    function getTemplates() {
        return templates;
    }

    function getSelectedTemplate() {
        return findTemplate(getTemplates(), getSelectedTemplateCustomName());
    }

    const isTemplateWarmOrFollowOrBye = /warm|followup|bye/i.test(getSelectedTemplateCustomName())

    const handleDeleteTab = (index) => {
        setCampaigns((prevTabs) => {
            const newTabs = [...prevTabs];
            newTabs.splice(index, 1);
            return newTabs;
        });
        setCurrentCampaignIndex(Math.max(0, index - 1));
    };

    const handleDateChosen = (chosenDate) => {
        return handleScheduledEmailsWithDialog.call(this, doScheduleMassEmails, chosenDate);
    };

    const handleClosePrompt = () => {
        setDateSelectionPromptOpen(false);
    };

    return (
        <>
            {isLoading && (
                <div className="loading-spinner-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <div className="leadlist-container" style={{pointerEvents: isLoading ? 'none' : 'auto'}}>
                <div className="button-row">
                    <input
                        id="upload-output-file-input"
                        type="file"
                        accept=".csv, .xls, .xlsx"
                        key={new Date()}  // Add a unique key to force re-render
                        onChange={handleImportLeadsFileChange.bind(this)}
                        style={{display: 'none'}}
                    />
                    <Button
                        onClick={handleImportLeadsFilePicker}
                        style={{marginLeft: '0px'}}
                    >
                        Import
                    </Button>
                    <Button
                        onClick={() => {
                            saveWorkspace()
                            alert('Workspace saved');
                        }}
                        style={{marginLeft: '20px'}}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={handleClearLeads}
                        style={{marginLeft: '20px'}}
                    >
                        Clear
                    </Button>
                </div>
                <div className="button-row">
                    <Button onClick={toggleCalendarVisibility}
                            style={{marginLeft: "0px"}}>
                        {isCalendarVisible ? 'Hide Calendar' : 'Show Calendar'}
                    </Button>
                    <Button
                        onClick={() => setDateSelectionPromptOpen(true)}
                        style={{marginLeft: "20px"}}>
                        Schedule
                    </Button>
                    <Button
                        onClick={handleCancelOutreachDuringBizHours}
                        style={{marginLeft: "20px"}}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleRefresh}
                        style={{marginLeft: "20px"}}>
                        Refresh
                    </Button>
                </div>
                <div className="calendar-container"
                     style={{marginLeft: "20px", display: isCalendarVisible ? 'block' : 'none'}}>
                    <CalendarOutreaches
                        scheduledOutreaches={scheduledOutreaches}
                        selectedDate={selectedDate}
                        handleDateClick={handleDateClick}
                        handleMouseLeave={handleMouseLeave}
                        tooltipScheduledPosition={tooltipScheduledPosition}
                        tooltipScheduledContent={tooltipScheduledContent}
                    />
                    {/* Images displayed below the Calendar */}
                    <div style={{marginTop: "20px", marginLeft: "-25px", display: "flex", justifyContent: "left"}}>
                        <img src={ScheduleImage} alt="Schedule"
                             style={{marginRight: "10px", maxWidth: "500px", maxHeight: "300px"}}/>
                        <img src={HolidaysImage} alt="Public holidays" style={{maxWidth: "500px", maxHeight: "300px"}}/>
                    </div>
                </div>
                <hr style={{border: '1px solid #ccc', width: '100%', margin: '20px 0'}}/>
                <div className="button-row">
                    {/* Search box for campaigns */}
                    <input
                        type="text"
                        placeholder="Search campaigns..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                            padding: '8px',
                            marginBottom: '10px',
                            width: '100%',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                        }}
                    />
                    {/* Dropdown to select a campaign */}
                    <select
                        value={currentCampaignIndex}
                        onChange={(e) => handleChangeTab(parseInt(e.target.value))}
                        style={{
                            padding: '8px',
                            backgroundColor: '#DDD',
                            color: '#000',
                            borderRadius: '4px',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        {campaigns
                            .filter(campaign => campaign.title.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map((campaign, index) => (
                                <option key={index} value={index}>
                                    {campaign.title}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="tabs-container-horizontal">
                    {campaigns.map((tab, index) => (
                        <div
                            key={index}
                            className={`tab ${index === currentCampaignIndex ? 'active-tab' : ''}`}
                            onClick={() => handleChangeTab(index)}
                        >
                            {tab.title}
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const newTitle = prompt('Enter new tab title:', tab.title);
                                    if (newTitle !== null && newTitle !== '') {
                                        handleUpdateTabTitle(index, newTitle);
                                    }
                                }}
                            >
                                ✎
                            </span>
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const confirmDelete = window.confirm(`Are you sure you want to delete the tab "${tab.title}"?`);
                                    if (confirmDelete) {
                                        handleDeleteTab(index);
                                    }
                                }}
                            >
                                🗑️
                            </span>
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCloneTab();
                                }}
                            >
                                📋
                            </span>
                        </div>
                    ))}
                    <div className="tab" onClick={handleAddTab}>
                        +
                    </div>
                </div>
                <div className='table-container'>
                    {campaigns.length > 0 && (
                        <>
                            <div className="input-group">
                                <label className="label">Subject</label>
                                <input
                                    placeholder="My email subject"
                                    className={`subject-input ${isTemplateWarmOrFollowOrBye ? 'disabled' : ''}`}
                                    type="text"
                                    value={campaigns[currentCampaignIndex].subject}
                                    onChange={handleChangeSubject}
                                    disabled={isTemplateWarmOrFollowOrBye}
                                    style={{maxWidth: '350px'}} // Adjust the maximum width as needed
                                />
                            </div>
                            <br/>
                            <div className="input-group">
                                <label className="label">Template</label>
                                <select className="select-input"
                                        value={getSelectedTemplateCustomName()}
                                        onChange={handleChangeTemplate}
                                        style={{maxWidth: '350px'}} // Adjust the maximum width as needed
                                >
                                    {templates.map((option) => (
                                        <option key={option.label} value={option.label}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <label className="label">{getSelectedTemplateBaseName()}</label>
                            </div>
                            <br/>
                            <div className="input-group">
                                <label className="label">File</label>
                                <input
                                    placeholder="Attachment Name"
                                    className="attachment-name-input"
                                    style={{minWidth: '250px', maxWidth: '350px'}}
                                    type="text"
                                    value={getSelectedTemplate() && getSelectedTemplate().attachmentName}
                                    onChange={handleAttachmentNameChange}
                                />
                                <label htmlFor="file-input" className="file-input-label">
                                    <img src={IconAttachment} alt="Attachment Icon" className="attachment-icon"/>
                                </label>
                                <input
                                    id="file-input"
                                    type="file"
                                    accept=".pdf"
                                    onChange={handleAttachmentFileChange}
                                    style={{display: 'none'}}
                                />
                                {getSelectedTemplate() && getSelectedTemplate().isAttachmentPresent && (
                                    <span className="remove-attachment" onClick={handleAttachmentRemoval}>
                                    x
                                </span>
                                )}
                            </div>

                            <br/>
                            <table>
                                <thead>
                                <tr>
                                    <th>X</th>
                                    <th>#</th>
                                    <th
                                        style={{position: 'relative'}}
                                        onMouseEnter={() => {
                                            setTooltipResponseRatePosition({top: '0px', left: '0px'})
                                            setTooltipResponseRateVisible(true)
                                        }}  // Update the position as needed
                                        onMouseLeave={() => {
                                            setTooltipResponseRatePosition(null)
                                            setTooltipResponseRateVisible(false)
                                        }}
                                    >
                                        ❤️
                                        {(tooltipResponseRateVisible &&
                                            <div
                                                className="tooltip-rate"
                                                style={{
                                                    top: tooltipResponseRatePosition && tooltipResponseRatePosition.top,
                                                    left: tooltipResponseRatePosition && tooltipResponseRatePosition.left,
                                                    display: tooltipResponseRatePosition ? 'block' : 'none',
                                                }}
                                            >
                                                {tooltipResponseRate}
                                            </div>
                                        )}
                                    </th>
                                    <th>Name</th>
                                    <th>Company</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Cold</th>
                                    <th>Warm</th>
                                    <th>FollowUp</th>
                                    <th>Bye</th>
                                    <th>🛑</th>
                                </tr>
                                </thead>
                                <tbody>
                                {campaigns[currentCampaignIndex].leads.map((lead, index) => (
                                    <TableRow
                                        key={index}
                                        lead={lead}
                                        index={index}
                                        outreachCount={outreachCount}
                                        template={getSelectedTemplateBaseName()}
                                        handleDeleteRow={handleDeleteRow}
                                        handleChange={handleChangeLeads}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
            <Button
                onClick={handleAddRowEmpty}
                style={{marginLeft: '20px'}}
            >
                New row
            </Button>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                onConfirm={handleConfirm}
            />
            <DateCalendarModal
                open={isDateSelectionModalOpen}
                onClose={handleClosePrompt}
                onDateChosen={handleOutreachDuringBizHours}
                scheduledOutreaches={scheduledOutreaches}

            />
        </>
    );
};

export default Home;
