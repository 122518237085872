import "./Button.css";

const Button = ({ children, onClick, ...props }) => {
    return (
        <button {...props} onClick={onClick} className="button-container">
            {children}
        </button>
    )
};

export default Button;