export function verifyLeads(campaigns, campaignIndex, assignAttachmentName, templates, findTemplate) {
    const isNameStartsWithCapitalLetter = (name) => /^[A-Z]/.test(name);
    const isOnlyEnglishLetters = (text) => /^[A-Za-z\s]*$/.test(text);
    const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    function areLeadsValid() {
        const result = {
            status: "FAILED",
            message: ''
        };
    
        const leads = campaigns[campaignIndex].leads;

        if (leads.length === 0) {
            result.message = 'No leads to outreach';
            return result;
        }

        const nonEmptyField = leads.every((lead, index) => {
            const ok = lead.name && lead.company && lead.email && lead.role;
            if (!ok) {
                result.message = `Field in lead at row ${index} is empty (name ${lead.name}, company ${lead.company}, email ${lead.email}, role ${lead.role})`;
            }
            return ok;
        });

        if (!nonEmptyField) {
            return result;
        }

        const companyNotContainsSpecialChar = leads.every((lead, index) => {
            const includes = lead.company.includes('&');
            if (includes) {
                result.message = `Company ${lead.company} includes '&' at row ${index}`;
            }
            return !includes;
        });

        if (!companyNotContainsSpecialChar) {
            return result;
        }

        const isNameCapital = leads.every((lead, index) => {
            const r = isNameStartsWithCapitalLetter(lead.name);
            if (!r) {
                result.message = `Name ${lead.name} does not start with a capital letter at row ${index}`;
            }
            return r;
        });

        if (!isNameCapital) {
            return result;
        }

        const isEnglishAlphabet = leads.every((lead, index) => {
            const r = isOnlyEnglishLetters(lead.name);
            if (!r) {
                result.message = `Name ${lead.name} contains non-English letters at row ${index}`;
            }
            return r;
        });

        if (!isEnglishAlphabet) {
            return result;
        }

        const isEmailOK = leads.every((lead, index) => {
            const v = isEmailValid(lead.email);
            if (!v) {
                result.message = `Email ${lead.email} contains unsupported characters at row ${index}`;
            }
            return v;
        });

        if (!isEmailOK) {
            return result;
        }

        result.status = 'OK';
        return result;
    }

    return areLeadsValid;
}