import {getApiUrl} from "../utils/getApiUrl";
import {fetchWithUserId} from "../utils/apiCalls";

export const addToStoplist = async (email, template, company) => {
    try {
        const result = await fetchWithUserId(`${getApiUrl()}/unsubscribe?email=${email}&template=${template}&company=${company}`);
        if (!result.ok) {
            // Handle non-successful HTTP responses
            throw new Error(`HTTP error! Status: ${result.status}`);
        }
        return true;
    } catch (error) {
        // Handle network errors, parsing errors, or any other unexpected issues
        console.error("An error occurred during the API call:", error);
        return false;
    }
};
