import React, {useState} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CalendarOutreaches from './CalendarOutreaches'; 
import { isSameDay } from 'date-fns';
import { isUSHoliday } from "../../../utils/isUSHoliday";

const DateCalendarModal = ({ open, onClose, onDateChosen, scheduledOutreaches, handleMouseLeave, tooltipScheduledPosition, tooltipScheduledContent }) => {
    const [chosenDate, setChosenDate] = useState(null)
    
    const onDateClick = (date) => {
        setChosenDate(date)
    }
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Choose a Date</DialogTitle>
            <DialogContent>
                <CalendarOutreaches
                    scheduledOutreaches={scheduledOutreaches}
                    handleDateClick={onDateClick}
                    handleMouseLeave={handleMouseLeave}
                    tooltipScheduledPosition={tooltipScheduledPosition}
                    tooltipScheduledContent={tooltipScheduledContent}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => { 
                            if (chosenDate) { 
                                onDateChosen(chosenDate); 
                                onClose(); 
                            }
                          }
                        } 
                        disabled={!chosenDate}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DateCalendarModal;
