import React from 'react';
import Calendar from 'react-calendar';
import { isSameDay } from 'date-fns';
import {isUSHoliday} from "../../../utils/isUSHoliday"; 

const CalendarOutreaches = ({ scheduledOutreaches, selectedDate, handleDateClick, handleMouseLeave, tooltipScheduledPosition, tooltipScheduledContent }) => {
    return (
        <Calendar
            onClickDay={handleDateClick}
            tileClassName={({ date }) => {
                const isHoliday = isUSHoliday(date);
                const isScheduledDate = scheduledOutreaches && scheduledOutreaches.some(scheduledDate => {
                    return isSameDay(new Date(scheduledDate.time), date);
                });

                if (isHoliday) {
                    return 'holiday';
                } else if (isScheduledDate) {
                    return 'scheduled';
                }

                return null; // Default to no additional class
            }}
            tileContent={({ date }) => {
                return isSameDay(selectedDate, date) ? (
                    <div
                        id="my-anchor-element"
                        onMouseLeave={handleMouseLeave}
                        style={{
                            background: "#fff",
                            position: 'absolute',
                            top: tooltipScheduledPosition.top,
                            left: tooltipScheduledPosition.left
                        }}
                    >
                        {tooltipScheduledContent}
                    </div>
                ) : null;
            }}
        />
    );
};

export default CalendarOutreaches;
